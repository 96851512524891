import { Grid, Typography, Box } from "@mui/material";
import poster from "../images/pexels-pixabay-247599.png";
import background from "../images/37436.svg";
import { LoginForm } from "../components";
import logo from "../images/Boomitra_Logo_Primary_Partial_White.png"
import { useNavigate } from "react-router-dom";
import buzz from "../images/Buzz1.png"

export const Login = () => {
  const navigate = useNavigate();
  const companyValues = [
    "Inclusivity",
    "Authenticity",
    "Passion",
    "Innovation",
    "Sustainability   ",
  ];
  

  return (
    <Grid
      container
      spacing={2}
      style={{ height: "100vh", margin: 0, width: "100vw", overflow: "hidden" }}
    >
      
      <Grid
        item
        xs={12}
        md={6}
        style={{
          backgroundImage: `url(${background})`,
          backgroundColor: "#004752",
          height: '100%'
        }}
      >
        
          <Grid
            container
            style={{
              width: "10%",
              paddingTop: 40,
              position: "relative",
              right: 50,
            }}
            direction="column"
            spacing={14}
            alignItems="center"
            alignContent="flex-start"
          >
            {companyValues.map((item, index) => (
              <Grid item sx={{ padding: 0 }} key={index}>
                <Typography
                  variant="h5"
                  style={{
                    color: "white",
                    transform: "rotate(-90deg)",
                  }}
                >
                  {item}
                </Typography>
              </Grid>
            ))}
          </Grid>

          <LoginForm />
        <Typography
          variant="h1"
          style={{
            position: "absolute",
            width: "167px",
            height: "24px",
            left: "385px",
            top: "655px",
            fontWeight: "200",
            fontSize: "20px",
            color: "#87C45E",
            cursor: "pointer",

          }}
          onClick ={() =>  navigate("/forgotPassword")}
        >
          Forgot Password ?
        </Typography>

        <Typography
          variant="h3"
          style={{
            position: "absolute",
            width: "453px",
            height: "51px",
            left: "195px",
            top: "188px",
            fontWeight: "400",
            fontSize: "24px",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            color: "#87C45E",
          }}
        >
          Building bridges across continents!
        </Typography>

        <Typography
          variant="h3"
          style={{
            position: "absolute",
            width: " 293px",
            height: "156px",
            left: "250px",
            top: "80px",
            fontWeight: "500",
            fontSize: "30px",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            color: "#87C45E",
          }}
        >
         <img
            src={buzz}
            alt="Logo"
            style={{ width: "210px", padding: "10px", height: "100px", cursor:"pointer" }}
          />
        </Typography>
      </Grid>
      <Box
        component={Grid}
        item
        md={6}
        style={{ padding: 0 }}
        display={{ md: "block", xs: "none",   }}
        sx ={{width: "100%", height: "100vh", backgroundImage: `url(${poster})`, backgroundSize:'cover' }}
      >
       <img
          src={logo}
          alt="Boomitra"
          style={{ width: "300px", height: "55px", zIndex: 10, margin: 20}}
        /> 
        
      </Box>
    </Grid>
  );
};
