const vision =
  "Inspiring action through innovation to build climate solutions for a resilient world."

const mission =
  "Unlock climate action at a gigatone scale through science-backed technology. Together with our partners, we empower farmers & landowners to create a planet that thrives.";

const values = 
  ["Sustainability: Balanced choices for a healthy planet.","Innovation: Continue to lead. ","Passion: It's in our DNA, wake up and live it. ","Authenticity: We are open, honest and straightforward." ,"Inclusivity: Everyone shares and everyone lives."]

export { vision, values, mission };
