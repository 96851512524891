import vm from "../images/vm.png";
import roadd from "../images/roadd.png"
export const training = [
    {
      banner:vm,
      heading: "VM0042 and Project Development Process",
      description:"VM0042 Methodology for improved agricultural land management",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:f:/p/patrick/ErgNGwizVPhOi31qwjjPpGoBCk8fCCqhT_5-yoixcjQ31g?e=1fhzPJ",
        "_blank"
        ),
      },
      {
        banner:roadd,
        heading: "R O A D",
        description:"Reward, OKRs, Appraisal, Development",
        pageId:"/road"
        }

    ]