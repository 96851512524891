import { React } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const CustomCard = ({ banner, heading, description, action, pageId }) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ maxWidth: 400, height: 320 }} onClick={action ? action : () => navigate(`${pageId}`)}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="200"
          image={banner}
          alt={heading}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{fontFamily: 'figtree',height:"100%"}}>
            {heading}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{fontFamily: 'inter',height:"100%"}}>
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
