import React, {useEffect} from "react";
import { NavBar, NewCard} from "../components";
import { Grid, Box } from "@mui/material";
import { branding } from "src/data/branding";

export const Branding = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <NavBar />
      <Grid container spacing={10} style={{padding: 20, marginTop: 20}}>
        {branding.map((item, index) => (
          <Grid item xs={3} key={index}>
            <NewCard
              key={index}
              banner={item.banner}
              heading={item.heading}
              description={item.description}
              action={item.action}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};