export const analytics = [
    {

      heading: "AI and the Future of Work",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/EaizpoZDy_RPvUYAeQyaeM0BBaGk8BY1_KjbaqEXov_aRw?e=cwBF5C",
        "_blank"
        ),
      },
  {
      heading: "AI for Agriculture Innovation",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/EVx4IXbFv9FAkFF7kcLNWDAB0ZxHpeGnsoWZPnNo6VYYIg?e=nG79Si",
        "_blank"
        ),
    },
    {

      heading: "Design Thinking Comes Of Age",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/Eej21hj62xNBkTN-OuUMbyoB4B1wb8rXYIN-O-3qh4ONow?e=vqsCkv",
        "_blank"
        ),
    },
    {

      heading: "Design Thinking",
      action: () =>
        window.open(
          "https://boomitra-my.sharepoint.com/:b:/p/patrick/EfehIznfvHFCl7UeSRnTJnwBvdN8UR4CLu0C8ZFKgKA_4g?e=n6tizn",
          "_blank"
        ),
    },
    {

      heading: " Make Advanced Analytics work ",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/EfZL6tLtMltJoTnKbjhb5xgBy5wn6y5cbFL4XVZeZX_ztw?e=mtZFUh",
        "_blank"
        ),
    },
    {

        heading: "Predictive Analytics in Practice",
        action: () =>
        window.open(
          "https://boomitra-my.sharepoint.com/:b:/p/patrick/Ee5Fi96YUptFgLQCOPDuk9MBCehzGWOdj_HZ67CLUOO4ww?e=eYkoyl",
          "_blank"
          ),
      },
      {

        heading: "The Next Analytics Page",
        action: () =>
        window.open(
          "https://boomitra-my.sharepoint.com/:b:/p/patrick/EQebTP8POXtBkMsTt71jKb8BA8hIp56DHEf2tPF26M4OwQ?e=cNattM",
          "_blank"
          ),
      }

  ];