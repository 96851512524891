/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Grid, Divider, Avatar } from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 600,
  bgcolor: "background.paper",
  border: "1px solid #004752",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

export const UserProfileModal = ({ open, setOpen, user }) => {
  const [userDet, setUserDet] = useState({});
  useEffect(() => {
    setUserDet(user?.userObj);
  }, [userDet, user]);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container spacing={2} height={200} alignItems={"center"}>
          <Grid item xs={10}>
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              color={"#004752"}
            >
              {userDet ? userDet.name?.S : "Not Found"}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Avatar
              alt="Profile"
              src={user?.url}
              sx={{ width: 96, height: 96 , marginBottom: 3}}
            />
          </Grid>
          <Divider orientation="horizontal" sx={{ width: "100%" }} />
          <Grid item xs={12}>
            {userDet ? (
              <>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Designation: </strong>
                  {userDet.designation?.S}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Birthday: </strong>
                  {userDet.birthday?.S}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Location: </strong>
                  {userDet.location?.S}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, color: '#3DB0DE', cursor: 'pointer' }} onClick={() => window.location = `mailto:${userDet.email?.S}`}>
                  <strong>E-mail: </strong> {userDet.email?.S}
                </Typography>
                <LinkedInIcon sx={{color: '#0177B5', marginTop: 4, fontSize:50,  cursor: 'pointer'}} onClick={() => window.open(userDet.linkedIn?.S, "_blank")}/>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
