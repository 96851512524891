import { useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Iconify } from "./Iconify";
import { Auth } from "aws-amplify";
import Loading from "react-fullscreen-loading";
import { useDispatch } from "react-redux";
import { addUser } from "../redux/userSlice";

export const LoginForm = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [confirmPass, setConfirmPass] = useState("");
  const [userObj, setUsrObj] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async () => {
    setLoading(true);
    if (mail.length === 0) {
      alert("Please provide your mail id.");
      setLoading(false);
      return;
    }
    if (password.length === 0) {
      alert("Please provide your password.");
      setLoading(false);
      return;
    }
    if (confirmPasswordShow) {
      try {
        await Auth.completeNewPassword(
          userObj, // the Cognito User Object
          password // the new password
        );
        Auth.signIn(mail, password)
          .then((user) => {
            dispatch(addUser(user.attributes))
            navigate("/home");
            setLoading(false);
          })
          .catch((err) => {
            alert("Error while signing in.");
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
      return;
    }

    Auth.signIn(mail, password)
      .then((user) => {
        setUsrObj(user);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setPassword("");
          setConfirmPasswordShow(true);
          setLoading(false);
        } else {
          Auth.signIn(mail, password)
            .then((user) => {
              dispatch(addUser(user.attributes))
              navigate("/home");
              setLoading(false);
            })
            .catch((err) => {
              alert("Error while signing in.");
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        alert("Error while signing in. Please check your e-mail and password.")
        console.log("Error while signing in. ", err);
        setLoading(false);
      });
  };
  return (
    <Grid
      container
      spacing={4}
      direction="column"
      style={{ position: "relative", bottom: 400 }}
    >
      <Loading
        loading={loading}
        background="rgba(0,0,0,0.3)"
        loaderColor="#3498db"
      />
      <Grid item>
        <TextField
          id="email"
          placeholder="Email"
          type={"email"}
          variant="outlined"
          fullWidth
          style={{
            backgroundColor: "white",
            borderRadius: 10,
            width: "50%",
            color: "black",
          }}
          value={mail}
          onChange={(event) => setMail(event.target.value)}
          
        />
      </Grid>
      <Grid item>
        <TextField
          id="password"
          placeholder={confirmPasswordShow ? "New Password" : "Password"}
          type={showPassword ? "text" : "password"}
          variant="outlined"
          style={{
            backgroundColor: "white",
            borderRadius: 10,
            width: "50%",
            color: "black",
          }}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {confirmPasswordShow ? (
        <Grid item>
          <TextField
            id="password"
            placeholder="Confirm Password"
            type={showPassword1 ? "text" : "password"}
            variant="outlined"
            style={{
              backgroundColor: "white",
              borderRadius: 10,
              width: "50%",
              color: "black",
            }}
            value={confirmPass}
            onChange={(event) => setConfirmPass(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword1(!showPassword1)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword1 ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ) : null}
      <Grid item>
        <Button
          variant="contained"
          sx={{
            bgcolor: "#87C45E",
            color: "#004850",
            width: "25%",
            fontWeight: "bold",
          }}
          onClick={onSubmit}
        >
          Login
        </Button>
      </Grid>
    </Grid>
  );
};
