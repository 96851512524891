/* eslint-disable react/prop-types */
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import IconButton from '@mui/material/IconButton';

export const FooterIcons = ({ sx }) => {
  const icons = [
    {
      name: 'facebook',
      icon: <FacebookIcon />,
      url: 'https://www.facebook.com/BoomitraHQ/'
    },
    {
      name: 'twitter',
      icon: <TwitterIcon />,
      url: 'https://twitter.com/boomitra'
    },
    {
      name: 'linkedin',
      icon: <LinkedInIcon />,
      url: 'https://www.linkedin.com/company/boomitra/'
    }
  ];

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div>
      {icons.map((item, index) => (
        <IconButton color="secondary" sx={sx} onClick={() => openInNewTab(item.url)} key={index}>
          {item.icon}
        </IconButton>
      ))}
    </div>
  );
};