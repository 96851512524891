import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const NewCard = ({ pageId,banner, heading, description, action}) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ maxWidth: 400, borderRadius: '5%', height:'100%' }} onClick={action? action : () => navigate(`${pageId}`)}>
      <CardActionArea>
        <CardMedia component="img" height="180" image={banner}  />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ fontFamily: "figtree", height: "100%" }}
          >
            {heading}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontFamily: "inter", height: "100%" }}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};