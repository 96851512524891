import { Box } from '@mui/material';
import { FooterIcons, FooterBody } from './Footer/index';
import Logo from "../images/Boomitra_Logo_Primary_Full_Color.png"

export const NoAuthFooter = () => {

  const openInNewTab = () => {
    window.open('https://boomitra.com/', '_blank', 'noopener,noreferrer');
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: '1rem',
          borderBottom: `1px solid black`,
          borderTop: '1px solid #ABAFC7',
          zIndex: 1000
        }}
      >
        <div style={{ flex: 1 }}>
        <Box
            onClick={openInNewTab}
            component="img"
            src={Logo}
            sx= {{width: '150', height: 25, marginRight: "100%"}}
            />
        </div>
        <FooterIcons />
      </div>
      <FooterBody />
    </div>
  );
};