import React, { useEffect } from "react";
import { NavBar } from "../components";
import { Box, Grid, Card, Typography } from "@mui/material";
import { policies } from "../data/policiesList";
import { useNavigate } from "react-router-dom";

export const Policies = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <NavBar />
      <Grid container spacing={10} style={{ padding: 20, marginTop: 15 }}>
        {policies.map((item, index) => (
          <Grid item xs={3} key={index}>
            <Card
              sx={{ minWidth: 275, cursor: "pointer", bgcolor: "#f7fff4" }}
              key={index}
              onClick={() => navigate(`Not Found`)}
            >
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                {item}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
