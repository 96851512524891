import { Action } from "@remix-run/router";
import { Navigate } from "react-router-dom";
import { AppDetails } from "src/pages/appDetails";


export const feature = [
    {
        heading: "Verification",
        onClick: () => {
          Action();
          Navigate(<AppDetails />);
        }
      },
    {
        heading: "Insights",
       
        
    },
    {
        heading: "Package Of Practice (POP)",
       
        
    },
    {
        heading: "Survey",
       
        
    },
      
     
    
    ];