import theme from "./theme/theme";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import Router  from "./router";
import { store } from "./redux/store";
import { addUser } from "./redux/userSlice";

if (window.localStorage.userDet) {
  
  const userDetails = JSON.parse(localStorage.getItem('userDet'));

  store.dispatch(addUser( userDetails ));
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router />
      </div>
    </ThemeProvider>
  );
}

export default App;
