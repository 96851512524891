import React from "react";
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

export const FullScreenComp = ({
  topic,
  heading,
  heading2,
  imgComp,
  direction,
  outerstyle,
  listItems,
  textObject,
  redirectLink,
}) => {
  return (
    <Box sx={{ flexGrow: 1,margin:3, height: "100vh", ...outerstyle }}>
      <Grid
        container
        spacing={2}
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {direction === "Left" ? (
          <>
            <Grid item xs={5} sx={{height:"70%"}}>
              {textObject ? (
                textObject.map((item, index) => (
                  <>
                    <Typography
                      variant="h4"
                      color="#F7B526"
                      fontSize={"220%"}
                      fontFamily={'figtree'}
                      sx={{ marginTop: 5}}
                      key={index}
                    >
                      {item.topic}
                    </Typography>
                    <Typography
                      variant="h4"
                      color="#3E4344"
                      fontSize={"150%"}
                      key={index}
                    >
                      {item.heading}
                    </Typography>
                  </>
                ))
              ) : (
                <>
                  <Typography
                    variant="h4"
                    color="#F7B526"
                    fontSize={"220%"} sx={{textAlign:"left",marginLeft:3 }}
                  >
                    {topic}
                  </Typography>
                  <Typography variant="h4" color="#3E4344" fontSize={"150%"} fontFamily={'inter'} sx={{marginTop:2,textAlign:"left",marginLeft:3  }}>
                    {heading}
                  </Typography>
                  {heading2 && (
                    <Typography variant="h4" color="#3E4344" fontSize={"150%"} fontFamily={'inter'} sx={{marginTop: 2,textAlign:"left",marginLeft:3, marginBottom: 1 }}>
                      {heading2}
                    </Typography> 
                  )}
                  {redirectLink ? (
                    <Typography
                      variant="body"
                      color="blue"
                      fontSize={"90%"}
                      onClick={() =>
                        window.open(
                          `https://boomitra.com/projects/${redirectLink}`,
                          "_blank"
                        )
                      }
                      sx={{ cursor: "pointer"}}
                    >
                      For more information
                    </Typography>
                  ) : null}
                </>
              )}
            </Grid>
            <Grid item xs={7} sx={{ height: "80%" }}>
              <img src={imgComp} alt="Vision" height="90%" width="95%" style={{borderRadius:5}} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={7} sx={{ height: "80%"}}>
              <img src={imgComp} alt="Vision" height="90%" width="90%" style={{borderRadius:5}} />
            </Grid>
            <Grid item xs={5} sx={{ height: "70%" }}>
              <Typography variant="h4" color="#F7B526" fontSize={"220%"} sx={{marginLeft:3 }}>
                {topic}
              </Typography>
              <Typography variant="h4" color="#3E4344" fontSize={"150%"} sx={{marginTop:3,textAlign:"left",marginLeft:3  }}>
                {listItems ? (
                  <List>
                    {listItems.map((item, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={item}
                          primaryTypographyProps={{ variant: "h5" }}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  heading
                )}
              </Typography>
              {heading2 && (
                <Typography variant="h4" color="#3E4344" fontSize={"150%"} fontFamily={'inter'} sx={{ marginTop: 2,textAlign:"left",marginLeft:3,marginBottom:1  }}>
                  {heading2}
                </Typography>   
              )}
              {redirectLink ? (
                <Typography
                  variant="body"
                  color="blue"
                  fontSize={"90%"}
                  onClick={() =>
                    window.open(
                      `https://boomitra.com/projects/${redirectLink}`,
                      "_blank"
                    )
                  }
                  sx={{ cursor: "pointer" }}
                >
                 For more information
                </Typography>
              ) : null}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};