import React from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Typography,
} from "@mui/material";
//import { useNavigate } from "react-router-dom";

export const UserCard = ({ banner, heading, pageId, action, description,setOpen, id, setLoading, setUserData }) => {
  //const navigate = useNavigate();
  const onClick = () => {
    setLoading(true)
    loadData("https://en2xgff4rce5si5zqnddiaquu40lmdyb.lambda-url.us-east-2.on.aws/?id="+id)
     
    console.log(id)
  }

  const loadData = (url) => {
    fetch(url, {
      method: "POST", 
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
  })
      .then(response => response.text())
      .then(data => {setUserData(JSON.parse(data)); setLoading(false); setOpen(true)})
      .catch(function (err) {
        setLoading(false)
        alert("Error while getting data")
        console.log("failed to load ", url, err.stack);
      })
  }
  
  return (
    <Card
      sx={{ maxWidth: 400 }}
      onClick={onClick}
    >
      <CardActionArea>
        <CardMedia height="200" alt={heading} />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            color="text.primary"
            fontFamily={"figtree"}
          >
            {heading}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            fontFamily={"inter"}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
