import { Grid, Link, Typography } from '@mui/material';

export const FooterBody = () => {

  const openInNewTab = (termConditions) => {
    if (termConditions) {
      window.open('https://boomitra.com/terms-conditions/', '_blank', 'noopener,noreferrer');
      return;
    }
    window.open('https://boomitra.com/privacy-policy/', '_blank', 'noopener,noreferrer');
  };

  return (
    <Grid
      container
      flexDirection='row'
      sx={{
        justifyContent: 'space-between',
        padding: '0.7rem 2rem',
        color: `black`,
        fontSize: '12'
      }}
    >
      <Grid item sm={6} md={4}>
        <Link
          component="button"
          underline="none"
          sx={{ color: `black`, fontSize: '12' }}
          onClick={() => openInNewTab(true)}
        >
          Terms of use
        </Link>
      </Grid>
      <Grid item sm={6} md={4}>
        <Link
          component="button"
          underline="none"
          sx={{ color: `black`, fontSize: '12' }}
          onClick={() => openInNewTab(false)}
        >
          Privacy Policy
        </Link>
      </Grid>
      <Grid item sm={12} md={4} sx={{
        width: '100%',
        textAlign: 'center'
      }}>
        <Typography variant="body1">
          Copyright © {new Date().getFullYear()}
        </Typography>
        <Typography variant="body1">
          Boomitra. All Rights Reserved.
        </Typography>
      </Grid>
    </Grid>
  );
};