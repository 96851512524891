import { Navigate, useRoutes } from "react-router-dom";
import {
  Login,
  Home,
  ComingSoon,
  MissionVision,
  Policies,
  PeopleDirectory,
  KnowledgeExchange,
  Projects,
  ForgotPassword,
  Page404,
  Road,
} from "./pages";
import { useSelector } from "react-redux";
import { LoginLayout } from "./layout/loginLayout";
import { Climate } from "./pages/climate";
import { Strategy } from "./pages/strategy";
import { Leadership } from "./pages/leadership";
import { Analytics } from "./pages/analytics";
import { Emotional } from "./pages/emotional";
import { Training } from "./pages/training";
import { Rocked } from "./pages/rockED";
import { Branding } from "./pages/branding";
import { Selflearn } from "./pages/selflearn";
import { Product } from "./pages/product";
import { AppDetails } from "./pages/appDetails";
import { Feature } from "./pages/Features";
import { SustainablePage } from "./pages";

export default function Router() {
  const user = useSelector((state) => state.user);
  const isAuthenticated = user ? true : false;

  return useRoutes([
    {
      path: "/",
      element: isAuthenticated ? (
        <LoginLayout/>
      ) : (
        <Navigate to="/login" />
      ),
      errorElement: <ComingSoon />,
      children: [
        { path: '/', element: <Navigate to="/home" /> },
        {
          path: "/home",
          element: <Home />,
        },
        
        {
          path: "mission",
          element: <MissionVision />,
        },
        {
          path: "policies",
          element: <Policies />,
        },
        {
          path: "people",
          element: <PeopleDirectory />,
        },
        {
          path: "knowledge",
          element: <KnowledgeExchange />,
        },
        {
          path: "projects",
          element: <Projects />,
        },
        {
          path: "comingSoon",
          element: <ComingSoon />
        },
        {
          path: "road",
          element: <Road />
        },
        {
          path: "branding",
          element: <Branding/>
        },
        {
          path: "climate",
          element: <Climate/>
        },
        {
          path: "strategy",
          element: <Strategy/>
        },
        {
          path: "analytics",
          element: <Analytics/>
        },
        {
          path: "leadership",
          element: <Leadership/>
        },
        {
          path: "emotional",
          element: <Emotional/>
        },
        {
          path: "training",
          element: <Training/>
        },
        {
          path: "rockED",
          element: <Rocked/>
        },
        {
          path: "selflearn",
          element: <Selflearn/>
        },
        {
          path: "product",
          element: <Product/>
        },
        {
          path: "product",
          element: <Product/>
        },
        {
          path: "apps",
          element: <AppDetails/>
        },
        {
          path: "feature",
          element: <Feature/>
        },
        {
          path: "sustainability",
          element: <SustainablePage/>
        }
      ],
    },
    {
      path: "/",
      element: !isAuthenticated ? <LoginLayout/> : <Navigate to="/" />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: 'forgotPassword', element: <ForgotPassword />}, 
        { path: '404', element: <Page404 />}
      ]
    },
    { path: '*', element: <Navigate to="/404" /> }
  ]);
}
