export const SustainablePage = () => {
  return (
    <div style={{
      maxWidth: '100vw',

    }}>
      <iframe title="weblow" id="videoiframe" src="https://stage.boomitra.com/"
        frameborder="0"
        style={{
          position: 'absolute',
          height: "100%",
          width: '100%',
          overflow: "hidden",
          display: "block"
        }}
      ></iframe>
    </div>

  );
}

