import React from "react";
import { NavBar,NewCard } from "../components";
import {knowledgexch} from "../data/knowledgeExch";
import { Box, Grid} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const KnowledgeExchange = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <NavBar />
      <Grid container spacing={10} style={{padding: 20, marginTop: 15}}>
        {knowledgexch.map((item, index) => (
          <Grid item xs={3} key={index}>
            <NewCard
              key={index}
              banner={item.banner}
              heading={item.heading}
              description={item.description}
              action={() => navigate(`${item.pageId}`)}
            />
          </Grid>
        ))}
      </Grid>
      </Box>
  );
};