import React, {useEffect} from "react";
import { NavBar, FullScreenComp } from "../components";
import { eastafricacroplands,eastafricagrasslands,indiaigp,indiasovin,mexico,pampas } from "../data/projects";
import { Box } from "@mui/material";
import eacImg from "../images/EAC.png";
import eagImg from "../images/EAG.png";
import igpImg from "../images/IGP.png";
import sovinImg from "../images/Sovin.png";
import mexicoImg from "../images/Mexico.png";
import pampasImg from "../images/Pampas.png";



export const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Box sx={{ flexGrow: 1,fontSize:'12px'}}>      <NavBar />      <FullScreenComp 
        topic={"East Africa - Croplands"} 
        heading={eastafricacroplands[0]} 
        heading2={eastafricacroplands[1]}
        imgComp={eacImg} 
        direction="Left" 
        redirectLink={"boomitra-carbon-farming-in-east-africa-through-soil-enrichment"}
        outerstyle={{marginLeft:2, marginTop:8}}
      />      <FullScreenComp 
        topic={"East Africa - Grasslands"}
        heading={eastafricagrasslands[0]}
        heading2={eastafricagrasslands[1]}
        imgComp={eagImg} 
        redirectLink={"boomitra-grassland-restoration-in-east-africa-through-soil-enrichment"} 
        outerstyle={{ marginRight:7, marginTop: "-10%" }}
      />      <FullScreenComp 
        topic={"India - Indo-Gangetic Plains"}
        heading={indiaigp[0]} 
        heading2={indiaigp[1]}
        imgComp={igpImg}  
        direction="Left" 
        redirectLink={"boomitra-carbon-farming-in-the-indo-gangetic-plains"}
        outerstyle={{ marginLeft: 2, marginTop: "-10%" }}
      />      <FullScreenComp 
        topic={"India - South of the Vindhyas"} 
        heading={indiasovin[0]}
        heading2={indiasovin[1]}
        imgComp={sovinImg}  
        redirectLink={"boomitra-carbon-farming-south-of-the-vindhyas"} 
        outerstyle={{ marginRight: 7, marginTop: "-10%" }}
      />      <FullScreenComp 
        topic={"Mexico - Grasslands"}
        heading={mexico[0]}
        heading2={mexico[1]}
        imgComp={mexicoImg}
        direction="Left"
        redirectLink={"boomitra-grassland-restoration-in-northern-mexico"}
        outerstyle={{ marginLeft: 2, marginTop: "-10%" }}
      />      <FullScreenComp 
        topic={"South America - Pampas"}
        imgComp={pampasImg} 
        heading={pampas[0]} 
        heading2={pampas[1]}
        redirectLink={"boomitra-grassland-restoration-in-the-temperate-grasslands-of-south-america-through-soil-enrichment"}
        outerstyle={{ marginRight:7, marginTop: "-10%" }}
      />    </Box>  );
};
