import { Grid, Card, CardContent, Typography, CardActionArea, CardHeader } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useState } from 'react';
import { AppDetails } from "./appDetails";
import login from "../images/login.png";
import q1 from "../images/doc1.png";
import FHome from "../images/FHome.png";
import one from "../images/one.png";
import two from "../images/two.png";
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import prof1 from "../images/prof1.png";
import prof2 from "../images/prof2.png";
import manage1 from "../images/manage1.png";
import manage2 from "../images/manage2.png";
import manage3 from "../images/manage3.png";
import manage4 from "../images/manage4.png";
import addranch1 from "../images/addranch1.png";
import addranch2 from "../images/addranch2.png";
import addranch3 from "../images/addranch3.png";
import insight1 from "../images/insight1.png";
import insight2 from "../images/insight2.png";
import insight3 from "../images/insight3.png";
import insight4 from "../images/insight4.png";
import three from "../images/three.png";
import RSignup1 from "../images/RSignup1.png";
import RSignup2 from "../images/RSignup2.png";
import four from "../images/four.png";
import q2 from "../images/Q1.png";
import logintwo from "../images/logintwo-removebg-preview.png";
import verify from "../images/verify.png";
import vhome from "../images/vhome.png";
import RHome from "../images/RHome.png";
import valert from "../images/valert.png";
import addR2 from "../images/AR2.png";
import ins1 from "../images/ins1.png";
import ins2 from "../images/ins2.png";
import EditIcon from '@mui/icons-material/Edit';
import doc1 from "../images/docs1.png";
import doc2 from "../images/docs2.png";
import man1 from "../images/M1.png";
import man2 from "../images/M2.png";
import man3 from "../images/M3.png";
import man4 from "../images/M4.png";
import DescriptionIcon from '@mui/icons-material/Description';
import FilterListIcon from '@mui/icons-material/FilterList';
import signupR1 from "../images/SignupR1.png";
import pop2 from "../images/pop2.jpg";
import pop3 from "../images/pop3.jpg";
import Rlogin from "../images/Rlogin1.png";
import verification4 from "../images/verification4.png";
import verification3 from "../images/verification3.png";
import verification5 from "../images/verification5.png";
import verification2 from "../images/verification2.png";
import LoginIcon from '@mui/icons-material/Login';
import { Sidebar } from "src/layout/siderbar";
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import MapIcon from '@mui/icons-material/Map';
import NotesIcon from '@mui/icons-material/Notes';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useNavigate } from "react-router-dom";
import { NoAuthFooter } from 'src/components';

const sideBarList = [
  {
    name: 'Farmer App',
    key: 1,
    heading: 'Farmer App Features',
    imgg: vhome,
    newFeatues: [
      {
          icon: <LoginIcon />,
          title: "Login",
          description: "",
          image: one,
          features: {
            text1: "Farmers will be able to log in to the FAR App using their registered phone number. This login functionality ensures that only authorised farmers can access the app and its features. The farmers can login by entering their registered phone number on the login page. Farmers have to enter the phone number and click 'Log in'" ,
            text2: "Farmers will be directed to the next page where they have to verify by entering the verification code recieved to their phone number. This functionality ensures that only authorised farmers can access the app and its features. There is also an option to Resend code in case they did not recieve any code",
            text3: "After entering the verification code, farmers will be able to log in to the FAR app. The home screen displays Carbon Credit Project Process and features which include - Verification, Insights, Feed, Surveys, Profile",
            heading: "Farmer App Login",
            heading1: 'Screen1: Login',
            heading2: 'Screen2: Verify it is you',
            heading3: 'Screen3: Home page',
            image3:FHome,
            image2: logintwo,
            image1: one,
            video: '',
            capabilities: {},
            features: [
                  {
                    icon: <VerifiedIcon />,
                    title: "Verification",
                    description: "After entering the Farmer's mobile number a verification code will be sent to the same number. Enter the code to verify."
                  },
                  {
                    icon: <InsightsIcon/>,
                    title: "Insights",
                    description: "In this page all the verified fields will be displayed for which insights can be viewed."
                  },
                  {
                    icon: <AgricultureIcon />,
                    title: "Surveys",
                    description: "Farmers will be able to view and answer surveys assigned to them."
                  },
                  // {
                  //   icon: <AgricultureIcon />,
                  //   title: "Package Of Practice",
                  //   description: "Farmer can view all the activities under the respective crop stages."
                  // },
                  {
                    icon: <AgricultureIcon />,
                    title: "Profile",
                    description: "Farmers details, bank details, option to change language, option to logout, app version can be accessed and viewed here."
                  },
                  {
                    icon: <AgricultureIcon />,
                    title: "Feed",
                    description: "This section is to give more information on soil moisture, NPK and also to knwo more about Carbon Credit Projects."
                  },
              ],
          }
        },
        {
          icon: <VerifiedIcon />,
          title: "Verification",
          description: "",
          image: two,
          features: {
            heading: "Farmer App Verification",
            text1: "Farmers can confirm and verify their fields using the Farmer app. To initiate the verification process, farmers should tap the 'verification' button found on the Home screen. They will be then directed to the Field Verification page where they will have to mark their location.",
            text2: " They are supposed to be in the center of their field and click 'Mark my location' to mark their current location. It is important to note that farmers must physically be in their field during the verification process; otherwise, they won't be able to complete the verification.",
            text3:"Once the field is successfully verified, farmers will see a confirmation indicator on the home page in the form of a green checkmark under 'Field Verification'. In the event of an unsuccessful verification attempt, an alert will prompt farmers to return to the center of their field to mark the location correctly",
            video:"",
            image2: verify,
            image1: vhome,
            image3: valert,
            capabilities: {},
            features: [
              {
                  icon: <LoginIcon />,
                  title: "Login",
                  description: "A farmer will be able to login to the Farmer app using their mobile number which that was used to onboard their field."
                },
                
                {
                  icon: <InsightsIcon/>,
                  title: "Insights",
                  description: "In this page all the verified fields will be displayed for which insights can be viewed."
                },
                {
                  icon: <AgricultureIcon />,
                  title: "Surveys",
                  description: "Farmers will be able to view and answer surveys assigned to them."
                },
                // {
                //   icon: <AgricultureIcon />,
                //   title: "Package Of Practice",
                //   description: "Farmer can view all the activities under the respective crop stages."
                // },
                {
                  icon: <AgricultureIcon />,
                  title: "Profile",
                  description: "Farmers details, bank details, option to change language, option to logout, app version can be accessed and viewed here."
                },
                {
                  icon: <AgricultureIcon />,
                  title: "Feed",
                  description: "This section is to give more information on soil moisture, NPK and also to knwo more about Carbon Credit Projects."
                },
                
            ],
          }
        },
        {
          icon: <LoginIcon />,
          title: "Insights",
          description: "",
          image: three,
          features: {
            text1: "The insights feature provides access to valuable information about the farmer's field. However, this feature can only be unlocked if the field is verified by the farmer. For cases where the farmer has multiple fields, the insights will only be shown for the verified ones. When the farmer clicks on the insights feature, they will see their fields displayed in different colours: green for verified fields and yellow for non-verified fields. If the farmer clicks on a verified field, they can choose from the following insights to view: Plant Health, Soil Moisture, Nitrogen, Phosphorus, and Potassium ",
            video1: "",
            video: '',
            text2: "After selecting any of the insights and specifying a date, the corresponding result will be displayed based on the concentration of the elements in that field.Additionally, there is a News feed at the bottom of every insights page to provide more information. The Plant Health and Soil Moisture insights provide decimal results, while Nitrogen, Phosphorus, and Potassium give percentage results",
            text3:"After selecting any of the insights and specifying a date, the corresponding result will be displayed based on the concentration of the elements in that field.Additionally, there is a News feed at the bottom of every insights page to provide more information. The Plant Health and Soil Moisture insights provide decimal results, while Nitrogen, Phosphorus, and Potassium give percentage results",
            text4:"After selecting any of the insights and specifying a date, of the elements in that field.Additionally, there is a News feed at the bottom of every insights page to provide more information. The Plant Health and Soil Moisture insights provide decimal results, while Nitrogen, Phosphorus, and Potassium give percentage results",
            image2: verification5,
            heading: "Farmer App Insights",
            image1: verification2,
            image3: verification3,
            image4: verification4,
            capabilities: {},
            features: [
              {
                  icon: <LoginIcon />,
                  title: "Login",
                  description: "A farmer will be able to login to the Farmer app using their mobile number which that was used to onboard their field."
                },
                {
                  icon: <VerifiedIcon />,
                  title: "Verification",
                  description: "After enetering the Farmer's mobile number a verification code will be sent to the same number. Enter the code to verify."
                },
                {
                  icon: <AgricultureIcon />,
                  title: "Surveys",
                  description: "Farmers will be able to view and answer surveys assigned to them."
                },
                // {
                //   icon: <AgricultureIcon />,
                //   title: "Package Of Practice",
                //   description: "Farmer can view all the activities under the respective crop stages."
                // },
                {
                  icon: <AgricultureIcon />,
                  title: "Profile",
                  description: "Farmers details, bank details, option to change language, option to logout, app version can be accessed and viewed here."
                },
                {
                  icon: <AgricultureIcon />,
                  title: "Feed",
                  description: "This section is to give more information on soil moisture, NPK and also to knwo more about Carbon Credit Projects."
                },
                
            ],
          }
        },
        {
          icon: <VerifiedIcon />,
          title: "Survey",
          description: "",
          image: four,
          features: {
            heading: "Farmer App Feed",
            text1: "Farmers can confirm and verify their fields using the Farmer app. To initiate the verification process, farmers should tap the 'verification' button found on the Home screen while standing at the center of their field. They must then mark their current location by clicking on the 'Mark my location' option. A success alert will be displayed upon successful verification. In the event of an unsuccessful verification attempt, an alert will prompt farmers to return to the center of their field to mark the location correctly ",
            text2: "It's important to note that farmers must physically be in their field during the verification process; otherwise, they won't be able to complete the verification. Once the field is successfully verified, farmers will see a confirmation indicator on the home page in the form of a green checkmark under 'Field Verification'",
            text3:"To initiate the verification process, farmers should tap the 'verification' button found on the Home screen while standing at the center of their field. They must then mark their current location by clicking on the 'Mark my location' option. A success alert will be displayed upon successful verification. In the event of an unsuccessful verification attempt, an alert will prompt farmers to return to the center of their field to mark the location correctly",
            video:"",
            image2: verify,
            image1: vhome,
            image3: valert,
            capabilities: {},
            features: [
              {
                  icon: <LoginIcon />,
                  title: "Login",
                  description: "A farmer will be able to login to the Farmer app using their mobile number which that was used to onboard their field."
                },
                // {
                //   icon: <AgricultureIcon />,
                //   title: "Package Of Practice",
                //   description: "Farmer can view all the activities under the respective crop stages."
                // },
                {
                  icon: <VerifiedIcon />,
                  title: "Verification",
                  description: "After enetering the Farmer's mobile number a verification code will be sent to the same number. Enter the code to verify."
                },
                {
                  icon: <InsightsIcon/>,
                  title: "Insights",
                  description: "In this page all the verified fields will be displayed for which insights can be viewed."
                },
                {
                  icon: <AgricultureIcon />,
                  title: "Profile",
                  description: "Farmers details, bank details, option to change language, option to logout, app version can be accessed and viewed here."
                },
                {
                  icon: <AgricultureIcon />,
                  title: "Feed",
                  description: "This section is to give more information on soil moisture, NPK and also to knwo more about Carbon Credit Projects."
                },
                
            ],
          }
        },
        {
          icon: <VerifiedIcon />,
          title: "Profile",
          description: "",
          image: four,
          features: {
            heading: "Farmer App Profile Section",
            text1: "The profile icon on the top right corner is how farmers can access and view their profile and details. The profile page contains farmer's name and options of 'View profile', 'Payment Details', 'Change language', 'Help'",
            text2: "Farmers can see their details and bank details in 'View profile' and 'Payment Details' respectively",
            text3:"Under 'Change language', farmers have option to change to their preferred language",
            video:"",
            image2: verify,
            image1: vhome,
            image3: valert,
            capabilities: {},
            features: [
              {
                  icon: <LoginIcon />,
                  title: "Login",
                  description: "A farmer will be able to login to the Farmer app using their mobile number which that was used to onboard their field."
                },
                
                {
                  icon: <InsightsIcon/>,
                  title: "Insights",
                  description: "In this page all the verified fields will be displayed for which insights can be viewed."
                },
                {
                  icon: <AgricultureIcon />,
                  title: "Surveys",
                  description: "Farmers will be able to view and answer surveys assigned to them."
                },
                // {
                //   icon: <AgricultureIcon />,
                //   title: "Package Of Practice",
                //   description: "Farmer can view all the activities under the respective crop stages."
                // },
                {
                  icon: <AgricultureIcon />,
                  title: "Feed",
                  description: "This section is to give more information on soil moisture, NPK and also to knwo more about Carbon Credit Projects."
                },
                {
                  icon: <VerifiedIcon />,
                  title: "Verification",
                  description: "After enetering the Farmer's mobile number a verification code will be sent to the same number. Enter the code to verify."
                },
                
            ],
          }
        },
        {
          icon: <VerifiedIcon />,
          title: "Feed",
          description: "",
          image: four,
          features: {
            heading: "Farmer App Feed",
            text1: "Farmers can confirm and verify their fields using the Farmer app. To initiate the verification process, farmers should tap the 'verification' button found on the Home screen while standing at the center of their field. They must then mark their current location by clicking on the 'Mark my location' option. A success alert will be displayed upon successful verification. In the event of an unsuccessful verification attempt, an alert will prompt farmers to return to the center of their field to mark the location correctly ",
            text2: "It's important to note that farmers must physically be in their field during the verification process; otherwise, they won't be able to complete the verification. Once the field is successfully verified, farmers will see a confirmation indicator on the home page in the form of a green checkmark under 'Field Verification'",
            text3:"To initiate the verification process, farmers should tap the 'verification' button found on the Home screen while standing at the center of their field. They must then mark their current location by clicking on the 'Mark my location' option. A success alert will be displayed upon successful verification. In the event of an unsuccessful verification attempt, an alert will prompt farmers to return to the center of their field to mark the location correctly",
            video:"",
            image2: verify,
            image1: vhome,
            image3: valert,
            capabilities: {},
            features: [
              {
                  icon: <LoginIcon />,
                  title: "Login",
                  description: "A farmer will be able to login to the Farmer app using their mobile number which that was used to onboard their field."
                },
                
                {
                  icon: <InsightsIcon/>,
                  title: "Insights",
                  description: "In this page all the verified fields will be displayed for which insights can be viewed."
                },
                {
                  icon: <AgricultureIcon />,
                  title: "Surveys",
                  description: ""
                },
                // {
                //   icon: <AgricultureIcon />,
                //   title: "Package Of Practice",
                //   description: "Farmer can view all the activities under the respective crop stages."
                // },
                {
                  icon: <VerifiedIcon />,
                  title: "Verification",
                  description: "After entering the Farmer's mobile number a verification code will be sent to the same number. Enter the code to verify."
                },
                {
                  icon: <AgricultureIcon />,
                  title: "Profile",
                  description: "Farmers details, bank details, option to change language, option to logout, app version can be accessed and viewed here."
                },
                
            ],
          }
        },
        // {
        //   icon: <LoginIcon />,
        //   title: "Package Of Practice",
        //   description: "",
        //   image: four,
        //   features: {
        //     text1: "Farmers now have the convenience of filling out the Package of Practice (POP) through our app. This feature is designed to streamline the process and make it easier for farmers to access and complete their POP. Each farmer's POP is assigned by their respective REP. Once the REPs assign the package to the farmers, they can instantly access it through the app.With the POP feature, farmers are able to select their specific crops and provide valuable information by completing a detailed questionnaire. This questionnaire focuses on the various stages of the crop and the practices that are specific to each crop. By answering these questions, farmers can ensure that they are implementing the best practices for their crops ",
        //     heading: "Farmer App Package Of Practice",
        //     video1: "BLNHOgy_HCI",
        //     video: 'https://boomitra-my.sharepoint.com/:v:/p/manasi/EWsiiuBGYeZGnQUElMHE4yYBwtQ4Os8LuhxUqPjkJrjtpg?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0RpcmVjdCJ9fQ&e=OnEIsR',
        //     text2: " Furthermore, farmers can conveniently track the progress of their questionnaire answers. This allows them to stay informed about the status of their POP and make any necessary adjustments or updates.The information provided by the farmers is of utmost importance to us. All the filled answers are carefully reviewed by our team at Boomitra. This allows us to gain valuable insights into the practices and challenges faced by farmers. To facilitate easy access, we have also included a QR code in this section. This QR code enables the REPs to easily access the farmers' POP. It provides a seamless and efficient way for REPs to stay updated on the progress and practices followed by the farmers under their supervision",
        //     image2: pop3,
        //     image1: pop2,
        //     capabilities: {},
        //     features: [
        //       {
        //           icon: <LoginIcon />,
        //           title: "Login",
        //           description: "A farmer will be able to login to the Farmer app using their mobile number which that was used to onboard their field."
        //         },
        //         {
        //           icon: <VerifiedIcon />,
        //           title: "Verification",
        //           description: "After enetering the Farmer's mobile number a verification code will be sent to the same number. Enter the code to verify."
        //         },
        //         {
        //           icon: <InsightsIcon/>,
        //           title: "Insighst",
        //           description: "In this page all the verified fields will be displayed for which insights can be viewed."
        //         },
        //         {
        //           icon: <AgricultureIcon />,
        //           title: "Profile",
        //           description: "Farmer can view all the activities under the respective crop stages."
        //         },
        //         {
        //           icon: <AgricultureIcon />,
        //           title: "Feed",
        //           description: "Farmer can view all the activities under the respective crop stages."
        //         },
                
                
        //     ],
        //   }
        // },
    ],
},
    {
        name: 'Rancher Dashboard',
        key: 2,
        heading: 'Rancher Dashboard Features',
        imgg: RHome,
        newFeatues: [
          {
              icon: <LoginIcon />,
              title: "Sign-Up / Login",
              description: "",
              image:RSignup1,
              features: {
                text1: "Sign up can be done through the Rancher dashboard by clicking on 'Signup' at the bottom of the Home screen. To signup, deatils such as Full Name, email id, password, partner id, country need to be filled. A verification code will be sent to the registered email-id",
                text2: "Enter the verification code sent to the email-id. You will now need to fill additional details such as address, postal code, city, etc. These details are not mandatory to fill, it can be done even after loggin in. After filling in all the required details and the code verification, rancher will see a confirmation of his/her registeration ",
                text3:"The Rancher is now successfully registered. Rancher will be able to login to the dashboard using their registered email-id and the password given at the time of registeration",
                video: "https://boomitra-my.sharepoint.com/:v:/p/manasi/EUgu2xa1ZetHosh18gTwzLUB97sAGDSvJuk0paFxcdjTwA?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0RpcmVjdCJ9fQ&e=PvhqHW",
                image1: RSignup1,
                heading: " Rancher Sign-up / Login",
                heading1: "Screen1: Sign-up Process",
                heading2: "Screen2: Email-Id Verification",
                heading3: "Screen3: Login",
                image2: RSignup2,
                image3: RSignup2,
                capabilities: {},
                features: [
                    {
                        icon: <PersonIcon />,
                        title: "Profile",
                        description: "Rancher can add and edit his/her details in the profile section"
                      },
                      {
                        icon: <VerifiedIcon />,
                        title: "Add Ranch",
                        description: "Ranchers can add their ranch and add ranch details and save it in the dashboard"
                      },
                      {
                        icon: <InsightsIcon />,
                        title: "Insights",
                        description: "Plant Health and Soil Moisture of ranches can be viewed"
                      },
                      {
                        icon: < VerifiedIcon/>,
                        title: "Management",
                        description: "Ranchers will be able to add their paddock, herds, infrastructure details here and manage their ranch"
                      },
                      {
                        icon: < NotificationAddIcon/>,
                        title: "Notification",
                        description: "Ranchers can also recieve In-app notifications in this dashboard"
                      },
                      
                  ],
              }
            },
            {
              icon: <LoginIcon />,
              title: "Profile",
              description: "",
              features: {
                text1: "The profile section displays all the details of the rancher which includes rancher's name, organtisation and role (not mandatory to fill), contact details, address.",
                video: "https://boomitra-my.sharepoint.com/:v:/p/manasi/Ecou5Ke0yrhOjvRKcLHvhQ8BPx0nrLKdYrB0yttYDe4bXQ?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0RpcmVjdCJ9fQ&e=4arfSd",
                text2: "If the rancher has not filled few of the personal details, they can fill it after loggin in as well. The ranchers will also be able to edit the profile details at any moment and save the same. There is also 'settings' option to change to preffered language and preferred area unit (acres/hectares) to change accordingly.",
                image2: prof2,
                heading1:'Screen1: Profile',
                heading2:'Screen2: Profile / Settings',
                heading: "Rancher Profile",
                image1: prof1,
                capabilities: {},
                features: [
                  {
                    icon: <PersonIcon />,
                    title: "Sign-up/Login",
                    description: "Ranchers will have to register to the Rancher Dashboard to be able to login "
                  },
                  {
                    icon: <VerifiedIcon />,
                    title: "Add Ranch",
                    description: "Ranchers can add their ranch and add ranch details and save it in the dashboard"
                  },
                  {
                    icon: <InsightsIcon />,
                    title: "Insights",
                    description: "Plant Health and Soil Moisture of ranches can be viewed"
                  },
                  {
                    icon: < VerifiedIcon/>,
                    title: "Management",
                    description: "Ranchers will be able to add their paddock, herds, infrastructure details here and manage their ranch"
                  },
                  {
                    icon: < NotificationAddIcon/>,
                    title: "Notification",
                    description: "Ranchers can also recieve In-app notifications in this dashboard"
                  },
                      
                  ],
              }
            },
            {
              icon: <VerifiedIcon />,
              title: "Add Ranch",
              description: "",
              features: {
                text1: "Click on the 'Add Ranch' button on the 'Home' page. Read the tutorial presented on the right part of the page. The tutorial video will guide you on how to Add your ranch. After completing the tutorial, click on 'Add Ranch'",
                text2: "You can either mark the polygon using the draw tool or upload a kml/kmz/shp file of your ranch by clicking on 'Upload file', you can upload your ranch's kml/kmz/shp file by browsing for the saved file. After Uploading the File, you will see your ranch on the map. Fill in all the details of the ranch on the right hand side. Click on 'Save' to add your ranch. Your added ranch will be visible under 'My Ranches' ",
                text3:"To draw a ranch, click on 'Draw' and mark your ranch respective to your location. Fill in the ranch details on the right hand side and click 'Save' to add the ranch. The ranch is now added and will be visible under 'My Ranches' ",
                video: "https://boomitra-my.sharepoint.com/:v:/p/manasi/EeAfHqT1x_lMlkQaFa2ylcQB_byxeJ3waJ4T4tgnbND4ag?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0RpcmVjdCJ9fQ&e=qquZL7",
                image2: addranch2,
                image1: addranch1,
                image3: addranch3,
                heading1:'Screen1: Add Ranch',
                heading2:'Screen2: Upload kml/kmz/shp ',
                heading3:'Screen3: Draw Ranch',
                heading: "Add Ranch / Ranches",
                capabilities: {},
                features: [
                  {
                    icon: <PersonIcon />,
                    title: "Profile",
                    description: "Rancher can add and edit his/her details in the profile section"
                  },
                  {
                    icon: <PersonIcon />,
                    title: "Sign-up/Login",
                    description: "Ranchers will have to register to the Rancher Dashboard to be able to login"
                  },
                  {
                    icon: <InsightsIcon />,
                    title: "Insights",
                    description: "Plant Health and Soil Moisture of ranches can be viewed"
                  },
                  {
                    icon: < VerifiedIcon/>,
                    title: "Management",
                    description: "Ranchers will be able to add their paddock, herds, infrastructure details here and manage their ranch"
                  },
                  {
                    icon: < NotificationAddIcon/>,
                    title: "Notifications",
                    description: "Ranchers can also recieve In-app notifications in this dashboard"
                  },
                      
                  ],
              }
            },
            {
              icon: <LoginIcon />,
              title: "Insights",
              description: "",
              features: {
                text1:"You can access the insights on Plant Health and Soil Moisture through the tab on the left side of the Home page. On click of the ‘Insights’ tab, you will see your marked ranches. On the right-hand side, you can see a list of your Ranches to direct you to the insights of that particular Ranch.",
                video:"https://boomitra-my.sharepoint.com/:v:/p/manasi/EXB8WzYXsO9Lq4QDAgh3mE0B5C9HwY0BYY_QglbDMpqCgg?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0RpcmVjdCJ9fQ&e=JDxQFu",
                text2:"You can click on your Ranch to get insights into that Ranch.On clicking the ranch you can access the two options of the insights - Plant Health (NDVI) and Soil Moisture.",
                text3:"On clicking Plant Health you will be getting the plant health data for last 30 days. The plant health of your ranch will be visible. You can also get more info on plant health by clicking on 'more info'",
                text4:"On clicking Soil Moisture you will be getting the Soil Moisture data for last 30 days. The Soil Moisture of your ranch will be visible. You can also get more info on Soil Moisture by clicking on 'more info'",
                image2: insight2,
                image1: insight1,
                image3: insight3,
                image4: insight4,
                heading4:'Screen4: Soil Moisture',
                heading3:'Screen3: Plant Health',
                heading2:"Screen2: Insights",
                heading1:'Screen1: Insights',
                heading: "Ranch Insights",
                capabilities: {},
                features: [
                  {
                    icon: <PersonIcon />,
                    title: "Profile",
                    description: "Rancher can add and edit his/her details in the profile section"
                  },
                  {
                    icon: <VerifiedIcon />,
                    title: "Add Ranch",
                    description: "Ranchers can add their ranch and add ranch details and save it in the dashboard"
                  },
                  {
                    icon: <PersonIcon />,
                    title: "Sign-up/Login",
                    description: "Ranchers will have to register to the Rancher Dashboard to be able to login"
                  },
                  {
                    icon: < VerifiedIcon/>,
                    title: "Management",
                    description: "Ranchers will be able to add their paddock, herds, infrastructure details here and manage their ranch"
                  },
                  {
                    icon: < NotificationAddIcon/>,
                    title: "Notifications",
                    description: "Ranchers can also recieve In-app notifications in this dashboard"
                  },
                      
                  ],
              }
            },
            {
              icon: <LoginIcon />,
              title: "Management",
              description: "",
              features: {
                text1: "Ranchers can manage their ranch using this feature. The ranchers will able to add the details of their paddock, herd, infrastructure. They can maintain the whole management details on this dashboard.",
                video:"https://boomitra-my.sharepoint.com/:v:/p/manasi/EXB8WzYXsO9Lq4QDAgh3mE0B5C9HwY0BYY_QglbDMpqCgg?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0RpcmVjdCJ9fQ&e=JDxQFu",
                text2: "By clicking on 'Add' ranchers can draw a paddock in their ranch and fill in the paddock details accordingly. These details will be saved and can be viewed on the map of their ranch",
                image2: manage2,
                heading:'Ranch Management',
                image1: manage1,
                text3: "Ranchers can add herds in the similar way by clicking 'Add' and filling the details accordingly. Infrastructure - water tanks, fences can also be added",
                text4: "There is also grazing plan, record of livestock purchase details, death records and rainfall records. All the activities made by the ranchers in tehir ranch can be tracked here. All the activities will be recorded in the dashboard",
                image4: manage4,
                image3: manage3,
                heading4: 'Screen4: Grazing Plan/Records',
                heading3: 'Screen3: Add Herds',
                heading2: 'Screen2: Add Paddocks',
                heading1: 'Screen1: Management Dashboard',
                capabilities: {},
                features: [
                  {
                    icon: <PersonIcon />,
                    title: "Profile",
                    description: "Rancher can add and edit his/her details in the profile section"
                  },
                  {
                    icon: <VerifiedIcon />,
                    title: "Add Ranch",
                    description: "Ranchers can add their ranch and add ranch details and save it in the dashboard"
                  },
                  {
                    icon: <InsightsIcon />,
                    title: "Insights",
                    description: "Plant Health and Soil Moisture of ranches can be viewed"
                  },
                  {
                    icon: < PersonIcon/>,
                    title: "sign-up/Login",
                    description: "Ranchers will have to register to the Rancher Dashboard to be able to login"
                  },
                  {
                    icon: < NotificationAddIcon/>,
                    title: "Notifications",
                    description: "Ranchers can also recieve In-app notifications in this dashboard"
                  },
                      
                  ],
              }
            },
            {
              icon: <LoginIcon />,
              title: "Notifications",
              description: "",
              features: {
                text1: "You can access the insights on Plant Health and Soil Moisture through the tab on the left side of the Home page. On click of the ‘Insights’ tab, you will see your marked polygons. On the right-hand side, you can see a list of your Ranch or Ranches to direct you to the insights of that particular Ranch.",
                video1: "https://boomitra-my.sharepoint.com/:v:/p/manasi/Edlm7_z6fkBOizeXXTxWGaYBffQxQ_u9vRc7tkqxpJgcmw?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0RpcmVjdCJ9fQ&e=tyvfmh",
                text2: "You can click on your Ranch to get insights into that Ranch. If you have multiple ranches, click on any Ranch you want to see and get insights from. On clicking the ranch you can access the two options of the insights - Plant Health (NDVI) and Soil Moisture.",
                image2: ins2,
                image1: ins1,
                heading:'Notifications',
                capabilities: {},
                features: [
                  {
                    icon: <PersonIcon />,
                    title: "Profile",
                    description: "Rancher can add and edit his/her details in the profile section"
                  },
                  {
                    icon: <VerifiedIcon />,
                    title: "Add Ranch",
                    description: "Ranchers can add their ranch and add ranch details and save it in the dashboard"
                  },
                  {
                    icon: <InsightsIcon />,
                    title: "Insights",
                    description: "Plant Health and Soil Moisture of ranches can be viewed"
                  },
                  {
                    icon: < VerifiedIcon/>,
                    title: "Management",
                    description: "Ranchers will be able to add their paddock, herds, infrastructure details here and manage their ranch"
                  },
                  {
                    icon: < PersonIcon/>,
                    title: "Sign-up/Login",
                    description: "Ranchers will have to register to the Rancher Dashboard to be able to login"
                  },
                      
                  ],
              }
            },
        ],
    },
    {
        name: 'Rep App',
        key: 3,
        imgg: vhome,
        newFeatues: [
          {
              icon: <LoginIcon />,
              title: "Sign Up",
              description: "",
              features: {
                text1: "As you open the REP App, the Sign-up Choice page displays two buttons, ‘Sign up’ and ‘Log in’ click on the Sign up Button. Enter your Country, Partner ID and the Leader ID which is optional. On the following page, enter your basic details: First Name, Middle Name, Last Name, Mobile Number, Email, Password (set up a new password), Address, Ward/Village, and Postal Code and select your respective County and sub-County.",
                video1: "BLNHOgy_HCI",
                text2: "After entering the details, click ‘Get Verification Code’ to receive a verification code sent to your Registered mobile number. Now enter the six-digit verification code that will be sent to your Mobile number and click on ‘verify’. Click on ‘resend code’ if you have not received one yet or if facing any issue. You will see a confirmation on the screen once you finish the registration process. Before you login, the account needs access from the Organisation to log in and start the onboarding",
                image2: FHome,
                image1: login,
                capabilities: {},
                features: [
                    {
                        icon: <LoginIcon />,
                        title: "Sign up",
                        description: "The REPs need to first sign up before they can login and access the REP app. To do so the REP details need to be added onthe sign up page."
                      },
                      {
                        icon: <VerifiedIcon />,
                        title: "Verification",
                        description: "After filling in the REP details they need to verify by filling in the OTP recived on their respective mobile number."
                      },
                      {
                        icon: <LoginIcon />,
                        title: "Registered",
                        description: "After verifying, the registeration is complete and the REP is now registered to login and onboard farmers."
                      },
                      {
                        icon: <LoginIcon />,
                        title: "Approval",
                        description: "Before loggin in the REP will need an approval from Boomitra. After approval, they can login to app."
                      },
                      
                  ],
              }
            },
            {
              icon: <LoginIcon />,
              title: "Login",
              description: "",
              features: {
                text1: "To log in, you need to select the ‘Login’ button on the Sign-Up Choice page and, select the country registered by you, enter your Mobile Number and password. Click on ‘Log in’ button after entering the credentials.",
                video1: "BLNHOgy_HCI",
                // text2: "Farmers can confirm and verify their fields using the Farmer app. To initiate the verification process, farmers should tap the 'verification' button found on the Home screen while standing at the center of their field. They must then mark their current location by clicking on the 'Mark my location' option. A success alert will be displayed upon successful verification. In the event of an unsuccessful verification attempt, an alert will prompt farmers to return to the center of their field to mark the location correctly. ",
                image2: FHome,
                image1: login,
                capabilities: {},
                features: [
                    {
                        icon: <LoginIcon />,
                        title: "Login",
                        description: "The REP cann log in using his/her mobile number and password given at the time of registeration"
                      },
                      {
                        icon: <VerifiedIcon />,
                        title: "Home Screen",
                        description: "After logging in the REP an access various features of the app on the Home screen - Add a field, Drafts, Submitted Apllications, POP, SDG Survey."
                      },
                      
                  ],
              }
            },
            {
              icon: <VerifiedIcon />,
              title: "Add a field",
              description: "",
              features: {
                text1: "You can find a button, 'Add a field', on the home page. Click on it to add your field. Enter and confirm the farmer's mobile number. Click on Confirm after providing the details. Here, you will see the Farmer's details provided during the Sign-Up. Click on ‘Continue’ after checking the Farmer's details to proceed to the next segment. If the Farmer is not onboarded, then the details of the Farmer will be asked to enter. Click on ‘continue’ after filling in the Farmer details to proceed to the next step. Select the ID Type you will provide on this page by clicking on the respective ID type from the drop-down list. Provide a clear image of the ID - the Front and the Back picture- or either can be uploaded. Select from the photos if already saved on your camera roll. You can also take a shot and upload it. These Options are provided on the page as mentioned. Click on ‘Continue’ to continue with the process. ",
                video1: "BLNHOgy_HCI",
                text2: "Here , you are required to enter the size of the field you are enrolling. This can be done in either acres / hectares by selecting from the dropdown provided. Please confirm after you have entered the correct details of the field size.The correct location of your field needs to be located by the marker on the Map. Marking can be done by finding the area and marking the centre of your field. By doing so, you can make a suitable polygon of your field, which gives us the accuracy of your area. Continue after locating the field on the Map. You will see the marker points on the map exactly in your field. You need to place the marker to the points of your field. Mark all the points of the field by placing the markers one by one. You will have a marked polygon of your area.",
                image2: FHome,
                image1: login,
                capabilities: {},
                features: [
                    {
                        icon: <LoginIcon />,
                        title: "Login",
                        description: "Farmer will be able to log in using their registered number securely using a verification code sent to the farmer's mobile number."
                      },
                      {
                        icon: <VerifiedIcon />,
                        title: "Mark my location",
                        description: "Farmers need to be in the centre of their field to verify. They can Mark their lcation and verify the same."
                      },
                      {
                        icon: <LoginIcon />,
                        title: "Verify",
                        description: "The verification process is complete after marking the location and an alert is displayed for the same."
                      },
                      {
                        icon: <LoginIcon />,
                        title: "Confirmation",
                        description: "After the farmer verifies their field, the confirmation can  be seen on the Home screen of the Farmer app."
                      },
                      
                  ],
              }
            },
            {
              icon: <LoginIcon />,
              title: "Drafts",
              description: "",
              features: {
                text1: " Farmers will be able to log in to the FAR App using their registered phone number and password. This login functionality ensures that only authorised farmers can access the app and its features.One crucial step in the farming journey is field verification. When a farmer onboards a field, they must verify it through the FAR app. This verification process ensures that the field's details are accurate and reliable. ",
                video1: "BLNHOgy_HCI",
                text2: "Farmers can confirm and verify their fields using the Farmer app. To initiate the verification process, farmers should tap the 'verification' button found on the Home screen while standing at the center of their field. They must then mark their current location by clicking on the 'Mark my location' option. A success alert will be displayed upon successful verification. In the event of an unsuccessful verification attempt, an alert will prompt farmers to return to the center of their field to mark the location correctly. ",
                image2: FHome,
                image1: login,
                capabilities: {},
                features: [
                    {
                        icon: <LoginIcon />,
                        title: "Login",
                        description: "Farmer will be able to log in using their registered number securely using a verification code sent to the farmer's mobile number."
                      },
                      {
                        icon: <VerifiedIcon />,
                        title: "Mark my location",
                        description: "Farmers need to be in the centre of their field to verify. They can Mark their lcation and verify the same."
                      },
                      {
                        icon: <LoginIcon />,
                        title: "Verify",
                        description: "The verification process is complete after marking the location and an alert is displayed for the same."
                      },
                      {
                        icon: <LoginIcon />,
                        title: "Confirmation",
                        description: "After the farmer verifies their field, the confirmation can  be seen on the Home screen of the Farmer app."
                      },
                      
                  ],
              }
            },
            {
              icon: <LoginIcon />,
              title: "Submitted Applications",
              description: "",
              features: {
                text1: " Farmers will be able to log in to the FAR App using their registered phone number and password. This login functionality ensures that only authorised farmers can access the app and its features.One crucial step in the farming journey is field verification. When a farmer onboards a field, they must verify it through the FAR app. This verification process ensures that the field's details are accurate and reliable. ",
                video1: "BLNHOgy_HCI",
                text2: "Farmers can confirm and verify their fields using the Farmer app. To initiate the verification process, farmers should tap the 'verification' button found on the Home screen while standing at the center of their field. They must then mark their current location by clicking on the 'Mark my location' option. A success alert will be displayed upon successful verification. In the event of an unsuccessful verification attempt, an alert will prompt farmers to return to the center of their field to mark the location correctly. ",
                image2: FHome,
                image1: login,
                capabilities: {},
                features: [
                    {
                        icon: <LoginIcon />,
                        title: "Login",
                        description: "Farmer will be able to log in using their registered number securely using a verification code sent to the farmer's mobile number."
                      },
                      {
                        icon: <VerifiedIcon />,
                        title: "Mark my location",
                        description: "Farmers need to be in the centre of their field to verify. They can Mark their lcation and verify the same."
                      },
                      {
                        icon: <LoginIcon />,
                        title: "Verify",
                        description: "The verification process is complete after marking the location and an alert is displayed for the same."
                      },
                      {
                        icon: <LoginIcon />,
                        title: "Confirmation",
                        description: "After the farmer verifies their field, the confirmation can  be seen on the Home screen of the Farmer app."
                      },
                      
                  ],
              }
            },
            {
              icon: <LoginIcon />,
              title: "SDG Survey",
              description: "",
              features: {
                text1: " Farmers will be able to log in to the FAR App using their registered phone number and password. This login functionality ensures that only authorised farmers can access the app and its features.One crucial step in the farming journey is field verification. When a farmer onboards a field, they must verify it through the FAR app. This verification process ensures that the field's details are accurate and reliable. ",
                video1: "BLNHOgy_HCI",
                text2: "Farmers can confirm and verify their fields using the Farmer app. To initiate the verification process, farmers should tap the 'verification' button found on the Home screen while standing at the center of their field. They must then mark their current location by clicking on the 'Mark my location' option. A success alert will be displayed upon successful verification. In the event of an unsuccessful verification attempt, an alert will prompt farmers to return to the center of their field to mark the location correctly. ",
                image2: FHome,
                image1: login,
                capabilities: {},
                features: [
                    {
                        icon: <LoginIcon />,
                        title: "Login",
                        description: "Farmer will be able to log in using their registered number securely using a verification code sent to the farmer's mobile number."
                      },
                      {
                        icon: <VerifiedIcon />,
                        title: "Mark my location",
                        description: "Farmers need to be in the centre of their field to verify. They can Mark their lcation and verify the same."
                      },
                      {
                        icon: <LoginIcon />,
                        title: "Verify",
                        description: "The verification process is complete after marking the location and an alert is displayed for the same."
                      },
                      {
                        icon: <LoginIcon />,
                        title: "Confirmation",
                        description: "After the farmer verifies their field, the confirmation can  be seen on the Home screen of the Farmer app."
                      },
                      
                  ],
              }
            },
            {
              icon: <LoginIcon />,
              title: "Scan QR",
              description: "",
              features: {
                text1: " Farmers will be able to log in to the FAR App using their registered phone number and password. This login functionality ensures that only authorised farmers can access the app and its features.One crucial step in the farming journey is field verification. When a farmer onboards a field, they must verify it through the FAR app. This verification process ensures that the field's details are accurate and reliable. ",
                video1: "BLNHOgy_HCI",
                text2: "Farmers can confirm and verify their fields using the Farmer app. To initiate the verification process, farmers should tap the 'verification' button found on the Home screen while standing at the center of their field. They must then mark their current location by clicking on the 'Mark my location' option. A success alert will be displayed upon successful verification. In the event of an unsuccessful verification attempt, an alert will prompt farmers to return to the center of their field to mark the location correctly. ",
                image2: FHome,
                image1: login,
                capabilities: {},
                features: [
                    {
                        icon: <LoginIcon />,
                        title: "Login",
                        description: "Farmer will be able to log in using their registered number securely using a verification code sent to the farmer's mobile number."
                      },
                      {
                        icon: <VerifiedIcon />,
                        title: "Mark my location",
                        description: "Farmers need to be in the centre of their field to verify. They can Mark their lcation and verify the same."
                      },
                      {
                        icon: <LoginIcon />,
                        title: "Verify",
                        description: "The verification process is complete after marking the location and an alert is displayed for the same."
                      },
                      {
                        icon: <LoginIcon />,
                        title: "Confirmation",
                        description: "After the farmer verifies their field, the confirmation can  be seen on the Home screen of the Farmer app."
                      },
                      
                  ],
              }
            },
            {
              icon: <LoginIcon />,
              title: "Assign POP to Farmer",
              description: "",
              features: {
                text1: " Farmers will be able to log in to the FAR App using their registered phone number and password. This login functionality ensures that only authorised farmers can access the app and its features.One crucial step in the farming journey is field verification. When a farmer onboards a field, they must verify it through the FAR app. This verification process ensures that the field's details are accurate and reliable. ",
                video1: "BLNHOgy_HCI",
                text2: "Farmers can confirm and verify their fields using the Farmer app. To initiate the verification process, farmers should tap the 'verification' button found on the Home screen while standing at the center of their field. They must then mark their current location by clicking on the 'Mark my location' option. A success alert will be displayed upon successful verification. In the event of an unsuccessful verification attempt, an alert will prompt farmers to return to the center of their field to mark the location correctly. ",
                image2: FHome,
                image1: login,
                capabilities: {},
                features: [
                    {
                        icon: <LoginIcon />,
                        title: "Login",
                        description: "Farmer will be able to log in using their registered number securely using a verification code sent to the farmer's mobile number."
                      },
                      {
                        icon: <VerifiedIcon />,
                        title: "Mark my location",
                        description: "Farmers need to be in the centre of their field to verify. They can Mark their lcation and verify the same."
                      },
                      {
                        icon: <LoginIcon />,
                        title: "Verify",
                        description: "The verification process is complete after marking the location and an alert is displayed for the same."
                      },
                      {
                        icon: <LoginIcon />,
                        title: "Confirmation",
                        description: "After the farmer verifies their field, the confirmation can  be seen on the Home screen of the Farmer app."
                      },
                      
                  ],
              }
            },
            
        ],
    },
    // {
    //   name: 'Partner Dashboard',
    //   key: 4,
    //   heading: 'Partner Dashboard Features',
    //   imgg: vhome,
    //   newFeatues: [
    //     {
    //         icon: <LoginIcon />,
    //         title: "Login",
    //         description: "",
    //         image: one,
    //         features: {
    //           text1: " Farmers will be able to log in to the FAR App using their registered phone number and password. This login functionality ensures that only authorised farmers can access the app and its features.One crucial step in the farming journey is field verification. When a farmer onboards a field, they must verify it through the FAR app. This verification process ensures that the field's details are accurate and reliable ",
    //           text2: "Farmers can confirm and verify their fields using the Farmer app. To initiate the verification process, farmers should tap the 'verification' button found on the Home screen while standing at the center of their field. They must then mark their current location by clicking on the 'Mark my location' option. A success alert will be displayed upon successful verification. In the event of an unsuccessful verification attempt, an alert will prompt farmers to return to the center of their field to mark the location correctly ",
    //           heading: "Partner dashboard",
    //           image2: logintwo,
    //           image1: one,
    //           video: 'https://boomitra-my.sharepoint.com/personal/tejas_boomitra_com/_layouts/15/stream.aspx?id=%2Fpersonal%2Ftejas%5Fboomitra%5Fcom%2FDocuments%2FFarmer%20App%20Training%20Video%5FKannada%2Emp4&ga=1',
    //           capabilities: {},
    //           features: [
    //                 {
    //                   icon: <VerifiedIcon />,
    //                   title: "Verification",
    //                   description: "After enetering the Farmer's mobile number a verification code will be sent to the same number. Enter the code to verify."
    //                 },
    //                 {
    //                   icon: <InsightsIcon/>,
    //                   title: "Inisghts",
    //                   description: "In this page all the verified fields will be displayed for which insights can be viewed."
    //                 },
    //                 {
    //                   icon: <AgricultureIcon />,
    //                   title: "Package Of Practice",
    //                   description: "Farmer can view all the activities under the respective crop stages."
    //                 },
    //             ],
    //         }
    //       },
      //     {
      //       icon: <VerifiedIcon />,
      //       title: "Verification",
      //       description: "",
      //       image: two,
      //       features: {
      //         heading: "Partner Dashboard",
      //         text1: "Farmers can confirm and verify their fields using the Farmer app. To initiate the verification process, farmers should tap the 'verification' button found on the Home screen while standing at the center of their field. They must then mark their current location by clicking on the 'Mark my location' option. A success alert will be displayed upon successful verification. In the event of an unsuccessful verification attempt, an alert will prompt farmers to return to the center of their field to mark the location correctly ",
      //         text2: "It's important to note that farmers must physically be in their field during the verification process; otherwise, they won't be able to complete the verification. Once the field is successfully verified, farmers will see a confirmation indicator on the home page in the form of a green checkmark under 'Field Verification'",
      //         text3:"To initiate the verification process, farmers should tap the 'verification' button found on the Home screen while standing at the center of their field. They must then mark their current location by clicking on the 'Mark my location' option. A success alert will be displayed upon successful verification. In the event of an unsuccessful verification attempt, an alert will prompt farmers to return to the center of their field to mark the location correctly",
      //         video:"https://boomitra-my.sharepoint.com/personal/tejas_boomitra_com/_layouts/15/stream.aspx?id=%2Fpersonal%2Ftejas%5Fboomitra%5Fcom%2FDocuments%2FFarmer%20App%20Training%20Video%5FKannada%2Emp4&ga=1",
      //         image2: verify,
      //         image1: vhome,
      //         image3: valert,
      //         capabilities: {},
      //         features: [
      //           {
      //               icon: <LoginIcon />,
      //               title: "Login",
      //               description: "A farmer will be able to login to the Farmer app using their mobile number which that was used to onboard their field."
      //             },
                  
      //             {
      //               icon: <InsightsIcon/>,
      //               title: "Insighst",
      //               description: "In this page all the verified fields will be displayed for which insights can be viewed."
      //             },
      //             {
      //               icon: <AgricultureIcon />,
      //               title: "Package Of Practice",
      //               description: "Farmer can view all the activities under the respective crop stages."
      //             },
                  
      //         ],
      //       }
      //     },
      //     {
      //       icon: <LoginIcon />,
      //       title: "Insights",
      //       description: "",
      //       image: three,
      //       features: {
      //         text1: "The insights feature provides access to valuable information about the farmer's field. However, this feature can only be unlocked if the field is verified by the farmer. For cases where the farmer has multiple fields, the insights will only be shown for the verified ones. When the farmer clicks on the insights feature, they will see their fields displayed in different colours: green for verified fields and yellow for non-verified fields. If the farmer clicks on a verified field, they can choose from the following insights to view: Plant Health, Soil Moisture, Nitrogen, Phosphorus, and Potassium ",
      //         video1: "BLNHOgy_HCI",
      //         video: 'https://boomitra-my.sharepoint.com/personal/tejas_boomitra_com/_layouts/15/stream.aspx?id=%2Fpersonal%2Ftejas%5Fboomitra%5Fcom%2FDocuments%2FFarmer%20App%20Training%20Video%5FKannada%2Emp4&ga=1',
      //         text2: "After selecting any of the insights and specifying a date, the corresponding result will be displayed based on the concentration of the elements in that field.Additionally, there is a News feed at the bottom of every insights page to provide more information. The Plant Health and Soil Moisture insights provide decimal results, while Nitrogen, Phosphorus, and Potassium give percentage results ",
      //         text3:"After selecting any of the insights and specifying a date, the corresponding result will be displayed based on the concentration of the elements in that field.Additionally, there is a News feed at the bottom of every insights page to provide more information. The Plant Health and Soil Moisture insights provide decimal results, while Nitrogen, Phosphorus, and Potassium give percentage results",
      //         text4:"After selecting any of the insights and specifying a date, the corresponding result will be displayed based on the concentration of the elements in that field.Additionally, there is a News feed at the bottom of every insights page to provide more information. The Plant Health and Soil Moisture insights provide decimal results, while Nitrogen, Phosphorus, and Potassium give percentage results",
      //         image2: verification5,
      //         heading: "Partner Dashboard",
      //         image1: verification2,
      //         image3: verification3,
      //         image4: verification4,
      //         capabilities: {},
      //         features: [
      //           {
      //               icon: <LoginIcon />,
      //               title: "Login",
      //               description: "A farmer will be able to login to the Farmer app using their mobile number which that was used to onboard their field."
      //             },
      //             {
      //               icon: <VerifiedIcon />,
      //               title: "Verification",
      //               description: "After enetering the Farmer's mobile number a verification code will be sent to the same number. Enter the code to verify."
      //             },
                  
      //             {
      //               icon: <AgricultureIcon />,
      //               title: "Package Of Practice",
      //               description: "Farmer can view all the activities under the respective crop stages."
      //             },
                  
      //         ],
      //       }
      //     },
      //     {
      //       icon: <LoginIcon />,
      //       title: "Package Of Practice",
      //       description: "",
      //       image: four,
      //       features: {
      //         text1: "Farmers now have the convenience of filling out the Package of Practice (POP) through our app. This feature is designed to streamline the process and make it easier for farmers to access and complete their POP. Each farmer's POP is assigned by their respective REP. Once the REPs assign the package to the farmers, they can instantly access it through the app.With the POP feature, farmers are able to select their specific crops and provide valuable information by completing a detailed questionnaire. This questionnaire focuses on the various stages of the crop and the practices that are specific to each crop. By answering these questions, farmers can ensure that they are implementing the best practices for their crops ",
      //         heading: "Partner Dashboard",
      //         video1: "BLNHOgy_HCI",
      //         video: 'https://boomitra-my.sharepoint.com/personal/tejas_boomitra_com/_layouts/15/stream.aspx?id=%2Fpersonal%2Ftejas%5Fboomitra%5Fcom%2FDocuments%2FFarmer%20App%20Training%20Video%5FKannada%2Emp4&ga=1',
      //         text2: " Furthermore, farmers can conveniently track the progress of their questionnaire answers. This allows them to stay informed about the status of their POP and make any necessary adjustments or updates.The information provided by the farmers is of utmost importance to us. All the filled answers are carefully reviewed by our team at Boomitra. This allows us to gain valuable insights into the practices and challenges faced by farmers. To facilitate easy access, we have also included a QR code in this section. This QR code enables the REPs to easily access the farmers' POP. It provides a seamless and efficient way for REPs to stay updated on the progress and practices followed by the farmers under their supervision",
      //         image2: pop3,
      //         image1: pop2,
      //         capabilities: {},
      //         features: [
      //           {
      //               icon: <LoginIcon />,
      //               title: "Login",
      //               description: "A farmer will be able to login to the Farmer app using their mobile number which that was used to onboard their field."
      //             },
      //             {
      //               icon: <VerifiedIcon />,
      //               title: "Verification",
      //               description: "After enetering the Farmer's mobile number a verification code will be sent to the same number. Enter the code to verify."
      //             },
      //             {
      //               icon: <InsightsIcon/>,
      //               title: "Insights",
      //               description: "In this page all the verified fields will be displayed for which insights can be viewed."
      //             },
                  
                  
      //         ],
      //       }
      //     },
      // ],
    // },
    {
      name: 'Inset Dashboard',
      key: 0,
      heading: 'Inset Dashboard',
  
    },
    {
      name: 'Kenya Audit Pictures',
      key: 5,
    }
];
 

export const Product = () => {
  const [selectedAppName, setSelectedAppName] = useState('Farmer App');
  const [viewMode, setViewMode] = useState('features'); 
  const [featureDetails, setFeatureDetails] = useState('')
  const [allFeatures, setAllFeatures] = useState('')
  const navigate = useNavigate();

  const handleClick = (name) => {
    console.log("Name: ",name)
    if(name === 'Inset Dashboard'){
      navigate("/sustainability")
      return
    }
      setSelectedAppName(name);
      setViewMode('features');
  }

  const handleCardClick = (feature, newFeatues) => {
      setFeatureDetails(feature)
      setAllFeatures(newFeatues)
      setViewMode('details');
  }

  
  const selectedApp = sideBarList.find(app => app.name === selectedAppName);


  

  return (
    <Grid container spacing={2} style={{ marginTop: '3rem', marginBottom: '2rem' }}>
      <Grid item xs={3}>
        <Sidebar handleClick={handleClick} activeApp={selectedAppName} sideBarList={sideBarList} />
      </Grid>
  
      <Grid item xs={9}>
        {viewMode === 'features' ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                align="center"
                style={{ marginBottom: '2rem' }}
              >
                {selectedApp?.heading}
              </Typography>
            </Grid>
            <Grid item xs={6}> {}
              <img src={selectedApp?.imgg} alt="description" style={{ width: '100%', height: 'auto' }} />
            </Grid>
            <Grid item xs={6}> {}
              <Grid container spacing={2}>
                {selectedApp?.newFeatues.map((feature, index) => (
                  <Grid item xs={12} key={index}> {}
                    <Card style ={{
                      boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.1)',
                      marginRight: '30px',
                      height: '80px', 
                      width: '80%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}>
                      <CardActionArea onClick={() => handleCardClick(feature.features, selectedApp?.newFeatues)}>
                        <CardHeader
                          sx={{ height: '80%', paddingTop: '2px' }}
                         
                          title={feature.title}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                          }}
                        />
                        <CardContent>
                          <Typography variant="body2" color="text.secondary">
                            {feature.description}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <AppDetails featureDetails={featureDetails} newFeatues={allFeatures} setFeatureDetails={setFeatureDetails} />
        )}
      </Grid>
     
    </Grid>
     
  );
}