import React from "react";
import { NavBar, CardDes} from "../components";
import { Grid, Box } from "@mui/material";
import { analytics } from "src/data/analytics";


export const Analytics = () => {

  return (
    <Box sx={{ flexGrow: 1 }}>
      <NavBar />
      <Grid container spacing={10} style={{padding: 20, marginTop: 15}}>
        {analytics.map((item, index) => (
          <Grid item xs={3} key={index}>
            <CardDes
              key={index}
              heading={item.heading}
              description={item.description}
              action={item.action}
              pageId={item.pageId}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};