

export const climate = [
  {
    heading: "Carbon Credits Glossary",
    action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/EVd5AvhVZFBHpqwkQCXkb1UBZ0xHrpaIGJDZA0mc3HLkxQ?e=3Qa49d",
        "_blank"
      ),
  },
  {
    heading: "Global assessment of soil carbon in grasslands",
    action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/EaqPeVJ0DMdOptesO4yoDr4BV6LV6SwVjmk2jJeJdfkUpQ?e=aF7Uyk",
        "_blank"
      ),
  },
  {
    heading: "Sustainable Agriculture, the New Green Revolution",
    action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/EVftd62r7OlBhsfMxN0Q-4ABanCyOw_UezHb5rsOsdglRg?e=BAStms",
        "_blank"
      ),
  },
  {
    heading: "A blueprint for scaling voluntary carbon markets",
    action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/EYC_Dq8lVX5Gr9grq7EhSnwBlEG0aM5G58TR09q__vX7Jg?e=l1vqu8",
        "_blank"
      ),
  },
  {
    heading: "10 Big findings from the 2023 IPCC Report on climate change",
    action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/EVExctCJWbtHulNYt5GHGD0B_9EdinF6yP-8asCj0JLo-A?e=bmINRc",
        "_blank"
      ),
  },
    {
      heading: "Paris Agreement Climate Change",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/ERRlxZB3Wr5HtpbXrn9P9AgBYXE-GuHIvauMeeG3bX30Xg?e=EdN8zg",
        "_blank"
        ),
      },
  {
      heading: "Paris Agreement for young people",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/EaJz_sOxjwlOuEdXq9xcvkoBheNTn7mGChCslixIg0m9pw?e=Yhuncs",
        "_blank"
        ),
    },
    {
  
      heading: "Grassland Soil Carbon",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/Eb2EtwG-Wy5NpDqg8LHCi1MBweGoxJq9OhRoNI7Mgv9tDw?e=bVoD3i",
        "_blank"
        ),
    },
    {
  
      heading: "Climate Fun-Facts ",
      action: () =>
        window.open(
          "https://boomitra-my.sharepoint.com/:b:/p/patrick/EQO9fhzMQz5Ci5pZ9EZ2OUsBIvMP2QoM3SCHqTu-tc8R6g?e=4TR2Hy",
          "_blank"
        ),
    },
    {
  
      heading: "Basics of climate change",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/EaBKBfv7QM5DmtkRWP1LtLgBR0l3EdXOGYzvNMDL1qpNog?e=Ozhdqh",
        "_blank"
        ),
    },
    {
      heading: "Desert grasslands of Mexico",
      action: () =>
        window.open("https://www.youtube.com/watch?v=XsmoJsRWK0Q", "_blank"),
    },
    {
      heading: "Grasslands - Holistic management",
      action: () =>
        window.open(
          "https://www.youtube.com/watch?v=Ca62KjDbjaQ&t=14s",
          "_blank"
        ),
    },
  
  ];