import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Logo from "../images/Boomitra_Logo_Primary_Partial_White.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import sidebarStyles from "./Sidebar.module.css";

export const Sidebar = ({ handleClick, activeApp, sideBarList }) => {
  const handleAction = () =>
    window.open(
      "https://boomitra-my.sharepoint.com/:f:/p/patrick/EgVvSPw8J0VLqu8XMuANMLoBRf4Q86EnhVC0p-qeN8nLXQ?e=jhtAAi",
      "_blank"
    );

  return (
    <Drawer
      variant="permanent"
      PaperProps={{ style: { width: 'inherit', backgroundColor: '#004752', color: '#FFF', zIndex: 0 } }}
      className={sidebarStyles.sidebar}
    >
      <div className={sidebarStyles.logoContainer}>
        <img src={Logo} alt="Company Logo" className={sidebarStyles.logo} />
      </div>
      <List>
        {sideBarList.map((app, index) => (
          <ListItem
            button
            key={index}
            onClick={() => {
              if (app.name === "Kenya Audit Pictures") {
                handleAction();
              } else {
                handleClick(app.name);
              }
            }}
            action={app.action}
            className={`${sidebarStyles.listItem} ${
              activeApp === app.name ? sidebarStyles.activeItem : ""
            }`}
          >
            <ListItemIcon className={sidebarStyles.listItemIcon}>
              <ArrowForwardIcon />
            </ListItemIcon>
            <ListItemText primary={app.name} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};
