export const strategy = [
    {

      heading: "What's your data Strategy?",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/ERpxc4i1pRNGrdD_z4tIEecBtbPuxVz5LnkC0Hli-u793Q?e=cxPMfU",
        "_blank"
        ),
      },
  {
      heading: "Implement New Strategy",
      description: "",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/EUV4HVxgCOVMtHA1PG7HZH4BPWi_XILs7t2ybZL2BTHO-g?e=vA5p4Y",
        "_blank"
        ),
    },
    {

      heading: "Shape Strategy",
      description: "",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/ES3ss1qTT5BIseHQXr9ExjsBvhPCLDG5tDS-yWXI3DKwFA?e=b3dU1l",
        "_blank"
        ),
    },
    {

      heading: "Think Strategically",
      description: "",
      action: () =>
        window.open(
          "https://boomitra-my.sharepoint.com/:b:/p/patrick/EREFvsHZt0RDuM_taLNKRZkBLgaHKyVQF2MJu9jV3eeIZQ?e=P6b1et",
          "_blank"
        ),
    },
    {

      heading: "What is your Strategy",
      description: "",
      action: () =>
        window.open(
          "https://boomitra-my.sharepoint.com/:b:/p/patrick/ET1-8SxYljJNvN8SO71UG-IB1AARkOJi4_PVmRlOENLlPQ?e=8pgjHu",
          "_blank"
        ),
    },
    {

      heading: "Blue Ocean Strategy",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/ERs2Fc5NW0pHqy6O4xzlkNQBISrVve-1_HjGfdeD14sAJg?e=1eEAVY",
        "_blank"
        ),
    }

  ];