export const emotional = [
    {

      heading: "Self Awareness",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/Ef3YjM6cdoBImcZm_sCPbvcBCtCGe2HpltMUZqiLt4KE0g?e=LgvOjj",
        "_blank"
        ),
      },
  {
      heading: "Primal Leadership",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/EY5A8IOAql5Ar2TC1nJ3JfkBgCQD3EeqQDyAc2xut9HPBg?e=xE4r7i",
        "_blank"
        ),
    },
    {
        heading: "Leadership that get Results",
        action: () =>
        window.open(
          "https://boomitra-my.sharepoint.com/:b:/p/patrick/EUk-5jemTrdFsRx9x35w7OIBCTApp4PoniXrkg_O66-o_g?e=5dC0ta",
          "_blank"
          ),
      },
      {
        heading: "Happiness",
        action: () =>
        window.open(
          "https://boomitra-my.sharepoint.com/:b:/p/patrick/ERHs0JjjpqlBvyN3RnTtxT8BL1uvsOXJkKJV6odboaEerA?e=98rbhj",
          "_blank"
          ),
      },
]