import climate from "../images/climate.png";
import leadership from "../images/leadership.png";
import strategy from "../images/strategy.png";
import analytics from "../images/analytics.png";
import emo from "../images/emo.png";


export const rockED = [
  
  {
    banner: climate,
    heading: "Climate",
    description: "Facts and Basics of Climate Change",
    pageId: "/climate",
  },
  {
    banner: leadership,
    heading: "Leadership",
    description: "Discover Leadership",
    pageId: "/leadership",
  },
  {
    banner: strategy,
    heading: "Strategy",
    description: "Understanding the competitive landscape",
    pageId: "/strategy",
  },
  {
    banner: analytics,
    heading: " AI, Analytics and Data",
    description: "Future of Work",
    pageId: "/analytics",
  },
  {
    banner: emo,
    heading: "Emotional Intelligence",
    description: "Understand, use, and manage your emotions",
    pageId: "/emotional",
  },
];
