import React from 'react'
import { NavBar } from "../components";
import { Typography, Grid } from '@mui/material';

export const ComingSoon = () => {
  return (
      <>
    <NavBar />
    <Grid container
        height='80vh'
        width='100%'
        justifyContent='center' alignItems='center'
        flexDirection='column'
    >
        <Typography
            fontWeight='bold'
            color='primary'
            sx={{
                fontSize: '2rem',
                textAlign: 'center'
            }}
        >
            Coming Soon
        </Typography>
        <Typography variant='p'
            fontWeight='bold'
            color='text.secondary'
            sx={{
                textAlign: 'center',
                margin: '0rem auto',
            }}
        >
            We are currently working on this page
        </Typography>
        <Typography variant='p'
            fontWeight='bold'
            color='text.secondary' sx={{
                margin: '0rem auto'
            }}>
            Follow us to stay updated
        </Typography>
        
    </Grid>
    </>
  )
}
