import React from "react";
import { NavBar, CustomCard, NoAuthFooter } from "../components";
import { Grid, Box } from "@mui/material";
import { HomeTabs } from "../data/homeTabs";
import { useSelector } from "react-redux";
import holiday from "../images/holiday.png";


export const Home = () => {
  const user = useSelector((state) => state.user);

  const playbookTab =  {
    banner: holiday,
    heading: "Playbooks",
    description: "Play Books",
    action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:f:/p/aditya/EoFBthD5pklKjr8c2nlEM8QBAeGHTn4LOsb0gSlDACTc4A?e=8ga28Y",
        "_blank"
      ),
  }
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <NavBar />
      <Grid container spacing={10} style={{padding: 20, marginTop: 15}}>
        {HomeTabs.map((item, index) => (
          <Grid item xs={3} key={index}>
            <CustomCard
              key={index}
              banner={item.banner}
              heading={item.heading}
              description={item.description}
              action={item.action}
              pageId={item.pageId}
            />
          </Grid>
        ))}
        {(user && user['custom:custom:role'] === 'BD') ? <Grid item xs={3} ><CustomCard
              banner={playbookTab.banner}
              heading={playbookTab.heading}
              description={playbookTab.description}
              action={playbookTab.action}
            /></Grid> : <></> } 
      </Grid>
      <NoAuthFooter />
    </Box>
   
  );
};
