import train from "../images/training.png";
import keroad from "../images/KEroad.png";
import selflearn from "../images/selflearn.png";

export const knowledgexch = [
  {
    banner: train,
    heading: "Mandatory Training",
    description:
      "Essential training for all Team Members, especially new hires",
    pageId: "/training",
  },
  {
    banner: keroad,
    heading: "RockED",
    description: "Learning Resource",
    pageId: "/rockED",
  },
  {
    banner: selflearn,
    heading: "Self-paced Learning Courses",
    description: "Augment your learning agility",
    pageId: "/selflearn",
  },
];
