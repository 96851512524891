export const leadership = [
    {

      heading: "Managing People",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/ERiYD4BaQZBGqu4TwylCziUB8wRb6t77ZOgXZUnkimeyBw?e=PvbziH",
        "_blank"
        ),
      },
  {
      heading: "Level 5 Leadership",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/EfNo_2SR1tJEs_ja8HN7vK8BfIlAs7_RxI7vvomhQq3_vQ?e=SI8H7u",
        "_blank"
        ),
    },
    {

      heading: "Leading Change",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/ETYKTVy3WIZOr2dtN8tNXI4BdSl2eNn8NLeMqg4wBG7c7w?e=08ePBx",
        "_blank"
        ),
    },
    {

      heading: "How Successful Leaders think",
      action: () =>
        window.open(
          "https://boomitra-my.sharepoint.com/:b:/p/patrick/Ef3tf8eRs3hPtIJCgt169e4BK2kkAv6YhdlT_UICR0jXWg?e=Linilw",
          "_blank"
        ),
    },
    {

      heading: "How Managers become",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/EWDAD6h6D5RMnBrmWiQKt4wB89rKz4rMxIRh2pDOtcIpzA?e=7i7Gcp",
        "_blank"
        ),
    },
    {

        heading: "Discovering Leadership",
        action: () =>
        window.open(
          "https://boomitra-my.sharepoint.com/:b:/p/patrick/EUYJU5BZtXFDrxNyZo9doo4Bxs90wLuUwXkzTrvbsnbLhw?e=o5Vkuc",
          "_blank"
          ),
      }

  ];