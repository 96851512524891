import React, { useEffect } from "react";
import { NavBar, FullScreenComp } from "../components";
import { Box } from "@mui/material";
import { mission, vision, values } from "../data/missionVision";
import visionImg from "../images/vision.png";
import valuesImg from "../images/values.png";

export const MissionVision = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const missionVision = [{
    topic: "Vision",
    heading: vision
  },
  {
    topic: "Mission",
    heading: mission
  }]
  return (
    <Box sx={{ flexGrow: 1 }}>
      <NavBar />
      <FullScreenComp
        topic={"Vision"}
        heading={vision}
        topic2={"Mission"}
        heading2={mission}
        imgComp={visionImg}
        textObject={missionVision}
        direction="Left"
        outerstyle={{ marginTop: "5%" }}
      />
      <FullScreenComp topic={"Values"} listItems={values} imgComp={valuesImg} outerstyle={{marginTop:"-5%"}} />
    </Box>
  );
};
