const eastafricacroplands = [ 
    "The occurrence of erratic rains and frequent droughts have imposed constraints on the productivity of croplands in Kenya. In addition to this, the degradation of land is accelerated by unsustainable land use and agricultural practices like land clearing, overgrazing, charcoal and wood extraction, cultivation on steep slopes, and soil nutrient mining. This has led to crop failures and livestock deaths, and further degraded soil and its organic matter.  ",
    "Boomitra’s restoration project aims to help farmers deploy improved cropland management and regenerative agriculture practices. The project activities include the adoption of practices such as no-till or reduced tilling, mulching with crop residues, reduced synthetic fertilizer application, use of organic manures, water management, and improved crop planting and harvesting methods. These practices lead to increase in soil organic carbon, preventing further degradation of croplands, and enabling farmers to improve the productivity. Additionally, the financial benefits from carbon credits ensures a direct boost to the livelihoods of the farmers involved in the project."
]
const eastafricagrasslands = [
    "Boomitra’s restoration project in East Africa encompasses the Savannah grasslands of Kenya, Uganda & Tanzania. The project area, characterized by arid and semi-arid regions, is prone to frequent drought conditions. Decades of unsustainable overgrazing by livestock in the region have resulted in excessive soil erosion, loss of soil organic carbon (SOC), biodiversity, and ecosystem services.  ", 
    "Boomitra partners with trusted local organizations, including the World Food Programme, Farm to Market Alliance, and Yara, to support pastoralists on these grasslands to implement improved grassland management practices, including rotational grazing and livestock management. These practices restore carbon to the soil and increase soil fertility, biodiversity, water infiltration, and soil water holding capacity. Over time, Boomitra monitors and quantifies the additional carbon sequestered and generates verified high-quality carbon removal credits. The majority of the proceeds from each carbon removal credit sold then go directly back to pastoralists, allowing them to re-invest in themselves and their communities. "
]
const indiaigp = [
    "The Indo-Gangetic Plains of India serve as the grain belt of the country, producing substantial amounts of wheat and rice. However, intensive agricultural processes and activities like stubble burning to clear crop residue, has led to a gradual degradation of the soils of the region, resulting in CO2 emissions from the lost soil carbon. Climate change has also led to much warmer weather, which has caused further decline of soil carbon. ",
    "Boomitra’s project helps farmers adopt new improved agriculture practices, like crop residue management, natural farming, crop rotations and more. We work with partner organizations to create feasible and sustainable solutions that promote behavioural change at the grassroots level and help farmers adapt. The project provides an opportunity to reverse soil degradation and return carbon to the soil, creating carbon removals while improving overall soil health. Additionally, the financial benefits from carbon credits will ensure a direct boost to the livelihoods of several small and marginal farmers involved with the project."
]
const indiasovin = [
    "Over the last century, agriculture in the south of Vindhyas region has undergone a dramatic shift towards intensification. Yields of staple crops saw a multi-fold increase correlated with an increase in the amount of synthetic fertilizer and other crop inputs applied to farms, resulting in gradual degradation of soil and soil organic matter. The croplands in the south of the Vindhyas are largely composed of small and marginal farmers, who remain highly susceptible to variabilities arising from existing markets and changing weather conditions.  ",
    "Boomitra’s project works with the farmers to adopt improved agricultural practices, such as reduced tillage, improved water and irrigation management, reduced fertilizer application, improved residue management, etc. These practices lead to increase soil organic carbon and enable farmers to improve their productivity. Our project partners implement various strategies and capacity-building programs to ensure a smooth transition to these practices, enabling the participant farmer to adapt and grow. The financial benefits arising from the carbon credits generated ensures a direct boost to the livelihoods of the farmers."
]
const mexico = [
    "Boomitra’s restoration project in Northern Mexico is in the Chihuahua Desert and the Sonoran Desert. These deserts have vast areas of grasslands, and the ranchers of the region raise livestock on individually owned or community-owned grasslands. However, traditionally followed practices, such as continuous grazing and conversion of grasslands into croplands, has resulted in severe land degradation, desertification, and loss of soil carbon, resulting in increased CO2 emissions.  ",
    "In Boomitra’s restoration project, we work with the ranchers of Northern Mexico and enable them to adopt improved grassland management practices. These practices include rotational grazing and a variety of water conservation measures. The livelihoods of the communities utilizing these ranches are improved directly through carbon credit payments, which provide an additional source of income, and indirectly through the increased productivity of their lands. Additionally, the project is expected to generate several community and biodiversity benefits."
]
const pampas = [
    "Boomitra’s grassland restoration project in South America encompasses the Pampas grasslands. Spread across the lowlands of Argentina, Brazil, Paraguay, and Uruguay, the region is under immense pressure from anthropogenic activities like overgrazing, and unsustainable grassland management activities. Grasslands in this region are declining steadily due to changes in land use, with grasslands being converted to arable land to produce animal feed crops. The conversion of grasslands to croplands is happening at a steady rate, resulting in the decline of soil carbon across the region. ",
    "Boomitra’s project involves the adoption of sustainable grassland management activities that will sequester organic carbon into the soil. Through carbon credit payments the livelihoods of the communities utilizing these grasslands will be improved directly through an additional source of income, and indirectly through increased productivity. The regenerated lands also become much less likely to undergo land use change, because the ranchers are able to sustain their livelihoods. This ensures that crucial habitats for the flora and fauna of the region are protected."
]
export { eastafricacroplands ,eastafricagrasslands, indiaigp, indiasovin, mexico, pampas };