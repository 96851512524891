import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Icon,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./appDetails.css"; // Ensure this points to your CSS file correctly

export const AppDetails = ({
  featureDetails,
  newFeatues,
  setFeatureDetails,
}) => {
  const {
    text1,
    image1,
    image2,
    text2,
    features,
    text3,
    video,
    image3,
    text4,
    image4,
    heading,
    heading1,
    heading2,
    heading3,
    heading4,
  } = featureDetails;

  const navigate = useNavigate();

  const handleCardClick = (title) => {
    const res = newFeatues.find((app) => app.title === title);
    setFeatureDetails(res.features);
  };

  return (
    <Box className="root">
      <Grid container spacing={5}>
        <Grid item xs={9}>
          <Typography variant="h4" className="heading">
            {heading}
          </Typography>
        </Grid>

        {/* Video Container */}
        <Grid item xs={10}>
          <div className="videoContainer">
            <iframe
              className="iframe"
              src={video}
              title="Video"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </Grid>

        <Grid item xs={12} style={{ height: "2rem" }}></Grid>

        {/* Text and Image Section 1 */}
        {/* Text and Image Section 1 */}
<Grid container spacing={5} alignItems="flex-start">
  {/* Heading and Text Section */}
  <Grid item xs={12} md={6} className="contentTextWithImage">
    <Typography variant="h4" className="titleHeading">
      {heading1}
    </Typography>
    <ul className="textList">
      {text1.split(".").map(
        (point, index) =>
          point.trim() && (
            <li key={index}>
              <Typography variant="h5" paragraph>
                {point.trim()}
              </Typography>
            </li>
          )
      )}
    </ul>
  </Grid>

  {/* Image Section */}
  <Grid item xs={12} md={6}>
    <Box className="imageBox">
      <img src={image1} alt="App Detail" className="contentImage" />
    </Box>
  </Grid>
</Grid>


        {/* Spacer */}
        <Grid item xs={12} style={{ height: "2rem" }}></Grid>

        {/* Text and Image Section 2 */}
        <Grid item xs={12} md={6}>
          <Box className="imageBox">
            <img src={image2} alt="App Detail" className="contentImage" />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          className="contentTextWithImage"
          sx={{ paddingRight: 10 }}
        >
          <Typography variant="h4" className="titleheading">
            {heading2}
          </Typography>
          <ul>
            {text2.split(".").map(
              (point, index) =>
                point && (
                  <li key={index}>
                    <Typography variant="h5" paragraph>
                      {point.trim()}
                    </Typography>
                  </li>
                )
            )}
          </ul>
        </Grid>

        <Grid item xs={12} style={{ height: "2rem" }}></Grid>

        {/* Text and Image Section 1 */}
        <Grid item xs={12} md={6} className="contentTextWithImage">
          <Typography variant="h4" className="titleheading">
            {heading3}
          </Typography>
          <Grid>
            <ul>
              {text3.split(".").map(
                (point, index) =>
                  point && (
                    <li key={index}>
                      <Typography variant="h5" paragraph>
                        {point.trim()}
                      </Typography>
                    </li>
                  )
              )}
            </ul>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className="imageBox">
            <img src={image3} alt="App Detail" className="contentImage" />
          </Box>
        </Grid>

        <Grid
          container
          spacing={4}
          justifyContent="flex-start"
          sx={{ paddingRight: 10, paddingTop: 5 }}
        >
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card className="fixedCard">
                <CardActionArea onClick={() => handleCardClick(feature.title)}>
                  <CardContent>
                    <Icon color="primary" fontSize="large">
                      {feature.icon}
                    </Icon>
                    <Typography variant="h6" component="div">
                      {feature.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {feature.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
