import excom  from "../images/ExComm.png";
import social from "../images/SocialMedia.png";
import vcall from "../images/vcall.png";
import fonts from "../images/fonts.png";
import bg from "../images/bg.png";
import esig from "../images/esig.png";
export const branding = [
  {
    banner: excom,
    heading: "External Communications Policy",
    description: "Defines Communication standards",
    action: () =>
    window.open(
      "https://boomitra-my.sharepoint.com/:f:/p/patrick/EqUBW6mevWtPhtKlkvtjEJMBlZF2HSR5BUhdLC281KclDA?e=QowyyY",
      "_blank"
      ),
    },
  {
    banner: social,
    heading: " Social Media Policy ",
    description: "Defines Social Media usage standards",
    action: () =>
    window.open(
      "https://boomitra-my.sharepoint.com/:f:/p/patrick/EixmpMwRpKtAte0bh6_ntMYBJ6BQDQg58NDILxeYpLodoA?e=eRCERI",
      "_blank"
      ),
  },
  {
    banner:bg ,
    heading: " Brand Guidelines ",
    description: "Do's and Dont's",
    action: () =>
    window.open(
      "https://boomitra-my.sharepoint.com/:b:/p/patrick/EXWoHzfs67FHnFzlVlsZ0_UB2pNiliEGKF5QHByJVDyvGw?e=ba5sSl",
      "_blank"
      ),
  },
  {
    banner:fonts ,
    heading: " Fonts ",
    description: "Download and use Boomitra's fonts",
    action: () =>
    window.open(
      "https://boomitra-my.sharepoint.com/:f:/p/patrick/EvuY8A5bniZDrqZe9mfPTqQBWa31bfT_wAYHl90SdDaMlQ?e=eXyk2j",
      "_blank"
      ),
  },
  {
    banner: vcall, 
    heading: " Videocall Backgrounds ",
    description: "Download and use Boomitra's videocall backgrounds",
    action: () =>
    window.open(
      "https://boomitra-my.sharepoint.com/:f:/p/patrick/EiNEuiaE03pJpHk4fi8qBy0B0lzvsM4VwDI6H6TEUEjiyQ?e=vQTblk",
      "_blank"
      ),
  },
  {
    banner: esig,
    heading: " Email Signature and other Templates",
    description: "Set up your Email signature",
    action: () =>
    window.open(
      "https://boomitra-my.sharepoint.com/:f:/p/patrick/Em2AIEs-D19JkW1uRXZzkeEBGjQaTyeQpgEH21d1ObXJXQ?e=hvc6vX",
      "_blank"
      ),
  },

];