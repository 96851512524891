import React, { useEffect, useState } from "react";
import { NavBar, UserCard, UserProfileModal } from "../components";
import { Box, Grid } from "@mui/material";
import { API } from "aws-amplify";
import Loading from "react-fullscreen-loading";

export const PeopleDirectory = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({});
  useEffect(() => {
    setLoading(true);
    API.get("api3d726432", "/users")
      .then((val) => {
        sortData(JSON.parse(val.body));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const sortData = (values) => {
    values.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    setAllUsers(values);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Loading
        loading={loading}
        background="rgba(0,0,0,0.3)"
        loaderColor="#3498db"
      />
      <UserProfileModal open={open} setOpen={setOpen} user={userData} />
      <NavBar />
      <Grid container spacing={10} style={{ padding: 20, marginTop: 15 }}>
        {allUsers.map((item, ind) => {
          return (
            <Grid item xs={3} key={ind}>
              <UserCard
                key={ind}
                heading={item.name}
                description={item.designation}
                setOpen={setOpen}
                id={item.id}
                setLoading={setLoading}
                setUserData={setUserData}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
