export const policies = [
  "Code of Conduct",
  "Progressive Discipline Policy",
  "Attendance Policy",
  "Employee PTO Policy",
  "Sick Leave Policy",
  "Bereavement Leave Policy",
  "Maternity Leave Policy",
  "Parental Leave Company Policy",
  "Short-term Disability Policy",
  " Flexible Working Hours Policy",
  "Remote Work Policy",
  "Work from Home Policy",
  "Resignation Policy",
  "Temporary Layoff Policy",
  "Mental Health Policy",
  "Occupational Workforce Health and Safety Policy",
  "Open Door Policy in the Workplace",
  "Dress Code Policy",
  "Employee Assistance Program Policy ",
  "Retaliation in the Workplace Policy",
  "Sexual Harassment Policy",
  "Smokefree Workplace Policy",
  "Substance Abuse Policy",
  "Violence in the Workplace Policy",
  "Workplace harassment Policy",
  "Third Party Harassment Policy",
  "External Communications Policy",
  "Social Media Policy",
  "Internet Usage Policy",
  "Cellphone Policy",
  "Agreement to Return and Care for Company Equipment",
  "Cyber Security Policy",
  "Company Data Protection Policy",
  "Confidential Information and Invention Assignment Agreement",
  "Employee Exit Policy",
  "Leave Policy – India",
  "Prevention of Sexual Harassment at the Workplace – India",
  "Medical & Accident Insurance Policy – India",
  "Code of Business Principles",
];
