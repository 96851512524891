import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: "",
  reducers: {
    addUser: (state, action) => {
      const user = action.payload;
      localStorage.setItem("userDet", JSON.stringify(user));
      return user;
    },
    removeUser: (state, action) => {
      localStorage.removeItem("userDet")
      return null;
    },
    getUser: (state, action) => {
      localStorage
        .getItem("userDet")
        .then((val) => {
          return JSON.parse(val);
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
    },
  },
});

export const { addUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
