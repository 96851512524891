import missionValues from "../images/missionvision.jpg";
import policies from "../images/policies.jpg";
import timesheet from "../images/timesheet.jpg";
import people from "../images/pexels-andrea-piacquadio-3931607.png";
import knowledge from "../images/pexels-element-digital-1370295.png";
import performance from "../images/pexels-sora-shimazaki-5668859.png";
import salary from "../images/pexels-karolina-grabowska-4497591.png";
import projects from "../images/a-farmer-cultivates-the-soil-on-the-site-of-an-alr-2022-08-01-01-49-19-utc.png";
import stocks from "../images/holdings.jpg";
import brand from "../images/brand.png";
import holiday from "../images/holiday.png";
import quiz from "../images/quiz.png";
import Onboarding from "../images/Onboarding.png";
import prodimg from "../images/prodimg.png";


export const HomeTabs = [
  {
    banner: missionValues,
    heading: "Vision, Mission, Values",
    description: "Our Mission, Vision, Values and Guiding Principles",
    pageId: "/mission",
  },

  {
    banner: prodimg,
    heading: "Boomitra Products",
    description: "Product Tutorials",
    pageId: "/product",
  },
  {
    banner: projects,
    heading: "Projects",
    description: "View Boomitra’s project portfolio",
    pageId: "/projects",
  },
  {
    banner: policies,
    heading: "Policies",
    description: "Policies and Principles that govern our workplace",
    action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:f:/p/patrick/EiVFnTg-gRZPg9IGG0ovBIEBI8gJdxsH5YO8OcRzEh6EsA?e=gwa8cW",
        "_blank"
      ),
  },
  {
    banner: timesheet,
    heading: "Timesheet",
    description: "Time tracking for team's work logs",
    action: () =>
      window.open(
        "http://timesheet-boomitra.s3-website.ap-south-1.amazonaws.com/",
        "_blank"
      ),
  },
  {
    banner: quiz,
    heading: "Quizzee Bees",
    description: "Tickle your grey cells",
    action: () =>
    window.open(
      "https://forms.office.com/r/ZMybcKdc9D",
      "_blank"
    ),
    
  },
 
  {
    banner: knowledge,
    heading: "Knowledge Exchange",
    description:
      "Information and resources to foster collaboration and continuous learning",
    pageId: "/knowledge",
  },
  {
    banner: performance,
    heading: "R O A D",
    description: "Reward, OKRs, Appraisal, Development",
    pageId: "/road",
  },
  
  {
    banner: people,
    heading: "People Directory",
    description: "Discover more about your colleagues",
    pageId: "/people",
  },
  {
    banner: salary,
    heading: "Salary Portal",
    description: "Access payroll information",
    action: () => window.open("https://www.ess.adp.in/ESS4/", "_blank"),
  },
  {
    banner: stocks,
    heading: "My Holdings",
    description: "Manage your Stock Options",
    action: () =>
      window.open("https://login.app.carta.com/credentials/login/", "_blank"),
  },
  {
    banner:brand,
    heading: "Branding Guidelines",
    description: "Branding Collaterals, External Communications and Social Media Guidelines",
    pageId: "/branding",

  },
  {
    banner: holiday,
    heading: "Holiday List",
    description: "List of Holidays across Boomitra's locations",
    action: () =>
      window.open("https://boomitra-my.sharepoint.com/:b:/p/patrick/EaBHP3C1DH9EiBT9KbbU0qMB9_QqrKtUgR5J93tJ5qO7VQ?e=8tmQDr", "_blank"),
  },
  {
    banner:Onboarding,
    heading: "Onboarding",
    description: "Indoctrination into Boomitra",
    action: () =>
      window.open("https://boomitra-my.sharepoint.com/:f:/p/patrick/EihqK4eAG7ZCpyeLTMCf0NwB5Qx5mAqUOLCFAmrwbgP1PQ?e=3ZajMU", "_blank"),
  },
   
];
