import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Logo from "../images/Boomitra_Logo_Primary_Partial_White.png";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { removeUser } from "../redux/userSlice";
import defaultAvatar from "../images/person.svg"

const settings = [ "Logout"];
export const NavBar = ({showAvatar}) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  useEffect(() => {
    console.log(user)
  },[])
  const handleCloseUserMenu = (event) => {
    const clickedValue = event.nativeEvent.target.outerText;
    if (clickedValue === "Logout") {
      Auth.signOut()
        .then(() => {
          dispatch(removeUser());
          navigate("/login");
        })
        .catch((err) => console.log(err));
    }
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: "#004752" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={Logo}
            alt="Logo"
            style={{ width: "20%", padding: "10px", height: "50px", cursor:"pointer" }}
            onClick={() => navigate("/home")}
          />
          {!showAvatar ? <><Typography variant="h6" sx={{margin: 3, color: '#ffffff', position: "absolute", left: "80%" }}> Hi  {user['custom:full_name']?.split(" ")[0]},</Typography>
          <Box sx={{ flexGrow: 0, position: "absolute", left: "95%" }}>
          
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Profile" src={defaultAvatar} />
              </IconButton>
            </Tooltip>
            
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Typography sx={{margin: 2, color: '#004850'}}>{user['custom:full_name']}</Typography>
              <Typography sx={{margin: 2, color: '#87C45E'}}>{user?.email}</Typography>
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> 
          </>: null}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
