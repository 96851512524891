import atingi from "../images/atingi.png";
import uncc from "../images/uncc.jpg";
import aitingi2 from "../images/aitingi2.png";
import aitingi3 from "../images/aitingi3.png";
import rocked1 from "../images/rocked1.png";
import rocked2 from "../images/rocked2.png";
import rocked3 from "../images/rocked3.png";

export const selflearn = [
    {
      banner:atingi,
      heading: "Atingi",
      description: "Register yourself",
      action: () =>
      window.open(
        "https://boomitra-my.sharepoint.com/:b:/p/patrick/Eec4AkC8lDJOnnNlvf1fbvEBEM5eZbsDATbDtM5bmggXZw?e=DhyaWk",
        "_blank"
        ),
      },
      {
        banner:aitingi2,
        heading: "Atingi",
        description: " Introduction to climate change",
        action: () =>
        window.open(
          "https://online.atingi.org/enrol/index.php?id=3022",
          "_blank"
          ),
        },
        {
          banner:aitingi3,
          heading: "Atingi",
          description: "Advanced concepts of climate change",
          action: () =>
          window.open(
            "https://online.atingi.org/enrol/index.php?id=3023",
            "_blank"
            ),


          },
          {
              banner:uncc,
              heading: "UNCC",
              description: "Register Yourself",
              action: () =>
          window.open(
            "https://boomitra-my.sharepoint.com/:b:/p/patrick/EWQrbXDt2nNKqnbe4rIgo9QBxCcGAK6DNxgbz9-TPZUILQ?e=FcjJIf",
            "_blank"
            ),

              },
              {
                  banner:rocked1,
                  heading: "VCS Program Details",
                  action: () =>
                  window.open(
                    "https://verra.org/programs/verified-carbon-standard/vcs-program-details/",
                    "_blank"
                    ),

                  },
                  {
                      banner:rocked2,
                      heading: "Revision to VM0042 Methodology",
                      description: "",
                      action: () =>
          window.open(
            " https://verra.org/methodologies/vm0042-methodology-for-improved-agricultural-land-management-v2-0/",
            "_blank"
            ),
                      },
                      {
                          banner:rocked3,
                          heading: "Voluntary Carbon Markets",
                          description: "",
                          action: () =>
          window.open(
            " https://www.spglobal.com/commodityinsights/en",
            "_blank"
            ),
                          },
    ]