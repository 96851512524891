import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const CardDes = ({ heading, pageId, action}) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ maxWidth: 400, borderRadius: '5%', height:'120%' }}  onClick={action ? action : () => navigate(`${pageId}`)}>
      <CardActionArea>
        <CardMedia  height="180" />
        <CardContent>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontFamily: "inter",fontSize:15}}
          >
            {heading}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};