import React, { useState } from "react";
import PasswordIcon from "../images/PasswordIcon";
import { Typography, Link, TextField, Box, InputAdornment, IconButton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Iconify, NavBar } from "../components";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

export const ForgotPassword = () => {
  const [email, setMail] = useState();
  const [submitButtonText, setSubmitButtonText] = useState("Send Request");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showFields, setShowFields] = useState(false);
  const [secondaryHeading, setSecondaryHeading] = useState(
    "Please enter the email address associated with your account and We will email you a 6 digit code to reset your password."
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const onSubmit = () => {
    setLoading(true);
    if (submitButtonText === "Send Request") {
      const re = /\S+@\S+\.\S+/;
      if (!re.test(email)) {
        alert("Please enter a valid email.");
        return;
      }
      Auth.forgotPassword(email)
        .then((data) => {
          setShowFields(true);
          setSubmitButtonText("Update Password");
          setSecondaryHeading(
            "We've sent a 6-digit confirmation code to your email. Please enter the code in below box to verify your email."
          );
          setLoading(false);
        })
        .catch((err) => {
          console.log("Error");
          setLoading(false);
        });
    } else {
      Auth.forgotPasswordSubmit(email, confirmationCode, password)
        .then((data) => {
          console.log(data);

          navigate("/login");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          alert("There was an error while resetting your password.");
        });
    }
  };
  return (
    <>
      <NavBar showAvatar={true}/>
      <PasswordIcon sx={{ mb: 5, height: 96, mt: 10 }} />
      <Box>
        <Typography variant="h3" paragraph>
          Forgot your password?
        </Typography>

        <Typography sx={{ color: "text.secondary", mb: 5 }}>
          {secondaryHeading}
        </Typography>

        <TextField
          placeholder="Email Address"
          variant="outlined"
          value={email}
          type="email"
          onChange={(event) => setMail(event.target.value)}
          sx={{ marginBottom: 2, width: 400 }}
        />
        {showFields ? (
          <>
            <Box>
              <TextField
                placeholder="Confirmation Code"
                variant="outlined"
                value={confirmationCode}
                onChange={(event) => setConfirmationCode(event.target.value)}
                sx={{ marginBottom: 2, width: 400 }}
              />
            </Box>
            <Box>
              <TextField
                placeholder="Password"
                variant="outlined"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                sx={{ marginBottom: 2, width: 400 }}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              <TextField
                placeholder="Confirm new password"
                variant="outlined"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                sx={{ marginBottom: 2, width: 400 }}
                type={showConfirmPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={showConfirmPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>{" "}
          </>
        ) : null}
      </Box>
      <Box>
        <LoadingButton
          loading={loading}
          variant="contained"
          sx={{
            bgcolor: "#87C45E",
            color: "#004850",
            width: "15%",
            fontWeight: "bold",
          }}
          onClick={onSubmit}
        >
          {submitButtonText}
        </LoadingButton>
      </Box>
      <Link
        to={"/login"}
        component={RouterLink}
        color="inherit"
        variant="subtitle2"
        sx={{
          mt: 3,
          mx: "auto",
          alignItems: "center",
          display: "inline-flex",
        }}
      >
        <Iconify icon="eva:chevron-left-fill" width={16} />
        Return to sign in
      </Link>
    </>
  );
};
