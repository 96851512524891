import roadimg from "../images/Road.png";
import ppt1 from "../images/pms.jpg";
import ppt2 from "../images/ppt2.png";
// import ppt3 from "../images/ppt3.png";
import form from "../images/form.png";

export const road = [
  {
    banner: roadimg,
    heading: "PMS Policy",
    description: "How Performance is Managed at Boomitra",
    action: () =>
    window.open(
      "https://boomitra-my.sharepoint.com/:b:/p/patrick/EbK6CcEV8ItAjyhV7wE2b5QBUBTf3bIFY49ayzvEAItBnQ?e=OcLUlA",
      "_blank"
      ),
    },
  {
    banner: ppt1,
    heading: "Slide Deck (1)",
    description: "Philosophy, OKRs, Appraisal, Reward, Development",
    action: () =>
    window.open(
      "https://boomitra-my.sharepoint.com/:f:/p/patrick/EkZhdoDlYKBAro98y9hZaHsBAENh8H-9ES0q0-IDm6izPA?e=DLjwUc",
      "_blank"
      ),
  },
  // {
  //   banner: ppt3,
  //   heading: "Video Presentation (1)",
  //   description: "Overall Road Process",
  //   action: () =>
  //     window.open(
  //       "https://boomitra-my.sharepoint.com/:v:/p/ganesh/EWZGWiJOEKVFnpIVkxMTpNgBLGipBSoFIRVDniz6Rih2RQ",
  //       "_blank"
  //     ),
  // },
  {
    banner: ppt2,
    heading: "Slide Deck (2)",
    description: "Setting OKRs, Rating Biases, Performance calibration",
    action: () =>
    window.open(
      "https://boomitra-my.sharepoint.com/:f:/p/patrick/EvdlJea52n5Em-ScWRxoXkoBvdwXkKiUAovkbPwLJTzIhA?e=lVIJkI",
      "_blank"
      ),
  },
  // {
  //   banner: ppt3,
  //   heading: "Video Presentation (2)",
  //   description: "Overall Road Process",
  //   action: () =>
  //     window.open(
  //       "https://boomitra-my.sharepoint.com/:v:/p/andrea/EfZegyPUAy9Bojylp-3A-_gBKwQ0AeIWqFT38hF-1T7U3A",
  //       "_blank"
  //     ),
  // },
  
  {
    banner: form,
    heading: "Objective Setting & Review FORM",
    description: "Format to Manage the Road Process",
    action: () =>
    window.open(
      "https://boomitra-my.sharepoint.com/:f:/p/patrick/EsS3cokPiEdPqH5gHewDnn4BBpipCE7JOSKcO8PPsNY7wQ?e=3m1GF8",
      "_blank"
      ),
  }
];